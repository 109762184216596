import axios from "axios";


const backendCall = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
    timeout: 5000,
    withCredentials: true
});



export default backendCall;