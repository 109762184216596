<template>
  <div class="bg-surface-50 dark:bg-surface-950 overflow-y-auto">
    <div class="grid grid-cols-12 gap-4">
      <TransitionGroup name="unitlist">
        <keep-alive>
      <FieldUnitCard v-for="(electric, index) in electrics" :key="electric.waybill_id" :alarms="props.alarms" :unit-number="electric.unit_number" :waybill-id="electric.waybill_id"
                     :setpoint-string="electric.complete_setpoint_c" :setpoint-celsius="parseFloat(electric.calculated_setpoint_c)" :setpoint-fahrenheit="parseFloat(electric.calculated_setpoint_f)" :sequence="electric.sequence" :clip-number="electric.clip_on" @remove="removeItem(index)" @submit-unit-info="$emit('submitUnitInfo', $event)" />
        </keep-alive>
      </TransitionGroup>
    </div>
  </div>
</template>
<script setup>
import FieldUnitCard from "./FieldUnitCard.vue";
const props = defineProps({electrics: Array, alarms: Array});
const emits = defineEmits(['submitUnitInfo']);

const removeItem = (index) => {
  props.electrics.splice(index, 1)
}
</script>

<style scoped>
.unitlist-enter-active,
.unitlist-leave-active {
  transition: all 0.5s ease;
}
.unitlist-enter-from,
.unitlist-leave-to {
  opacity: 0;
  transition: all 0.5s ease;}
</style>