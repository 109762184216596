<template>
  <div class="bg-surface-0 dark:bg-surface-900 shadow rounded-border p-6">
    <div class="flex justify-between items-center mb-8">
      <span class="flex justify-center">
      <span class="text-xl text-surface-900 dark:text-surface-0 font-medium mr-2">{{props.title}}</span>
        <span > <Badge :value="clips.length"></Badge> </span>
</span>

      <div>
        <button class="p-button p-component p-button-icon-only p-button-text p-button-plain p-button-rounded"
                type="button" data-pc-name="button" data-pc-section="root" data-pd-ripple="true"><span
            class="p-button-icon pi pi-ellipsis-v" data-pc-section="icon"></span><span class="p-button-label"
                                                                                       data-pc-section="label">&nbsp;</span>
          <!----><span role="presentation" aria-hidden="true" data-p-ink="true" data-p-ink-active="false"
                       class="p-ink" data-pc-name="ripple" data-pc-section="root"></span></button>
        <!--teleport start--><!--teleport end--></div>
    </div>
    <ul v-for="clip in clips" :key="clip.clip_number" class="list-none p-0 m-0">
      <ClipListItem :clip-number="clip.clip_number" :list-type="props.listType" :removal-time="clip.removal_time" :event-id="clip.event_id" @update-clips="$emit('update-clips')"/>
    </ul>
  </div>
</template>
<script setup>
import ClipListItem from "./ClipListItem.vue";

const props = defineProps(['clips','title', 'listType']);
const emit = defineEmits(['update-clips']);


</script>