<script setup>
import { ref } from 'vue';
import InputGroupAddon from "primevue/inputgroupaddon";
import InputGroup from "primevue/inputgroup";
import backendCall from "../../services/AxiosService.js";
import {useToast} from "primevue/usetoast";
const toast = useToast();
const props = defineProps({clipNumber: String, eventId: Number, listType: String});
const visible = defineModel();
const emit = defineEmits(['update-clips']);

const fuelOptions = ref(['E', '1/8', '1/4', '3/8', '5/16', '7/16', '1/2', '9/16', '5/8', '3/4', '13/16', '7/8', 'F']);


const pretripFormData = ref({
  eventId: props.eventId,
  clipNumber: props.clipNumber,
  fuelLevel: null,
  notes: null,
  pretripPass: null
});

const submitClipPretrip = async () => {
  try {
    let url;
    if (props.listType === 'pretrip-required') {
      url = '/clip-ons/pretrip'
    } else if (props.listType === 'failed-pretrip') {
      url = '/clip-ons/pretrip/resolve'
    } else { throw new Error('Invalid List Type')
    }
    const postClipPretripCall = await backendCall.post( url, pretripFormData.value);
    if (!postClipPretripCall) {
      throw new Error('Could not submit clip-on pretrip')
    }
    emit('update-clips');
    visible.value = false;
    toast.add({
      severity: 'success',
      summary: 'Success!',
      detail: `${pretripFormData.value.clipNumber} pretrip has been recorded.`,
      life: 3000
    });
  } catch (e) {
    toast.add({severity: 'error', summary: 'Error!', detail: 'Could not submit clip-on pretrip.', life: 5000});
  }
}
</script>

<template>

  <Dialog v-model:visible="visible" modal :style="{ width: '25rem' }">
    <template #header>
      <h1 class="m-0 text-surface-900 dark:text-surface-0 font-semibold text-xl leading-normal">Pretrip {{ clipNumber }}</h1>
    </template>
    <template #default class="pb-0">
    <form class="flex flex-col gap-4 mt-4">
      <div class="flex gap-4">
        <div class="w-full">
          <label for="fuel-level" class="block mb-1 text-color text-base">Fuel Level</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-gauge text-lg"></i>
            </InputGroupAddon>
            <Dropdown v-model="pretripFormData.fuelLevel" :options="fuelOptions.toReversed()"/>
          </InputGroup>
        </div>
      </div>
      <div class="flex flex-col justify-center items-center gap-2">
        <div>
          <label for="notes" class="block mb-1 text-color text-base">Notes</label>
          <Textarea id="notes" name="notes" v-model="pretripFormData.notes" rows="3" cols="36"></Textarea>
        </div>
        <div>
          <SelectButton v-model="pretripFormData.pretripPass" :options="[{name: 'Pass', value: true},{name: 'Fail', value: false}]" option-label="name" option-value="value" aria-labelledby="basic">

</SelectButton>

        </div>
      </div>
    </form>
      </template>
    <template #footer>
      <div class="flex gap-4 justify-end border-t border-surface pt-8">
        <Button label="Cancel" @click="visible = false" class="p-button-text"></Button>
        <Button label="Submit" @click="submitClipPretrip" class="p-button-rounded"></Button>
      </div>
    </template>
  </Dialog>
</template>

<style scoped>

</style>