<script setup>
import {ref} from 'vue';
import {onMounted} from "vue";
import backendCall from "../../services/AxiosService.js";
import {useToast} from "primevue/usetoast";
import NewUserDialog from "../../components/admin/NewUserDialog.vue";
import {FilterMatchMode} from '@primevue/core/api';

const newUserDialogVisible = ref(false);
const activeTab = ref(0);
const trainEventSubscriptions = ref(null);


const toast = useToast();
const users = ref([]);
const items = ref([{
  label: 'Edit User',
  icon: 'pi pi-user',
  command: () => {
    toast.add({severity: 'warn', summary: 'Search Completed', detail: 'No results found', life: 3000});
  }
},
  {
    label: 'Disable User',
    icon: 'pi pi-times',
    command: () => {
      toast.add({severity: 'success', summary: 'Success', detail: 'File created', life: 3000});
    }
  }]);

const tabs = ref([{
  label: 'Users',
  icon: 'pi pi-user',
  command: () => {
    toast.add({severity: 'warn', summary: 'Search Completed', detail: 'No results found', life: 3000});
  }
},
  {
    label: 'Notifications',
    icon: 'pi pi-bell',
    command: () => {
      toast.add({severity: 'success', summary: 'Success', detail: 'File created', life: 3000});
    }
  }]);


const filters = ref({
  global: {value: null, matchMode: FilterMatchMode.CONTAINS},
  name: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
  'country.name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
  representative: {value: null, matchMode: FilterMatchMode.IN},
  status: {value: null, matchMode: FilterMatchMode.EQUALS},
  verified: {value: null, matchMode: FilterMatchMode.EQUALS}
});

onMounted(async () => {
  const userRequest = await backendCall.get('admin/users');
  users.value = userRequest.data.data.users;
  console.log('USERS: ', users);

  const trainEventSubscriptionRequest = await backendCall.get('admin/train-subscriptions');
  trainEventSubscriptions.value = trainEventSubscriptionRequest.data.data;
})

</script>

<template>

  <div class="bg-surface-0 dark:bg-surface-950 px-6 pt-8 md:px-12 lg:px-12">
    <div class="text-3xl font-medium text-surface-900 dark:text-surface-0 mb-6">Admin</div>
    <!--    <TabMenu :model="tabs"></TabMenu>-->


    <ul class="bg-surface-0 dark:bg-surface-900 p-0 m-0 list-none flex overflow-x-auto select-none">
      <li class="flex-1">
        <a v-ripple
           class="cursor-pointer px-6 py-4 flex items-center justify-center border-b-2 hover:border-surface-500 dark:hover:border-surface-300 transition-colors duration-150 p-ripple"
           :class="{'border-blue-500 text-blue-500 hover:border-blue-500': activeTab === 0, 'text-700 border-transparent': activeTab !== 0}"
           @click="activeTab = 0">
          <i class="pi pi-users mr-2"></i>
          <span class="font-medium">Users</span>
        </a>
      </li>
      <li class="flex-1">
        <a v-ripple
           class="cursor-pointer px-6 py-4 flex items-center justify-center border-b-2 hover:border-surface-500 dark:hover:border-surface-300 transition-colors duration-150 p-ripple"
           :class="{'border-blue-500 text-blue-500 hover:border-blue-500': activeTab === 1, 'text-700 border-transparent': activeTab !== 1}"
           @click="activeTab = 1">
          <i class="pi pi-bell mr-2"></i>
          <span class="font-medium">Notifications</span>
        </a>
      </li>
      <li v-if="false" class="flex-1">
        <a v-ripple
           class="cursor-pointer px-6 py-4 flex items-center justify-center border-b-2 hover:border-surface-500 dark:hover:border-surface-300 transition-colors duration-150 p-ripple"
           :class="{'border-blue-500 text-blue-500 hover:border-blue-500': activeTab === 2, 'text-700 border-transparent': activeTab !== 2}"
           @click="activeTab = 2">
          <i class="pi pi-cog mr-2"></i>
          <span class="font-medium">Settings</span>
        </a>
      </li>
    </ul>

  </div>
  <section v-show="activeTab === 0"
           class="bg-surface-0 dark:bg-surface-900 px-6 lg:px-12 py-6 border-surface">
    <!--    <div class="border-b border-surface">-->
    <!--      <h2 class="mt-0 mb-2 text-surface-900 dark:text-surface-0 font-bold text-4xl">Members & Roles</h2>-->
    <!--      <p class="mt-0 mb-8 text-surface-700 dark:text-surface-100 font-normal text-base">You can easily manage your team on this page</p>-->
    <!--    </div>-->

    <section class="flex flex-col flex-wrap gap-4 py-12 justify-between border-surface">
      <div class="shrink-0 w-60">
        <h3 class="mb-6 mt-0 text-surface-900 dark:text-surface-0 font-medium text-xl">Members</h3>
        <p class="mb-6 mt-0 text-surface-700 dark:text-surface-100 font-normal text-base">Manage user accounts</p>
        <Button @click="newUserDialogVisible = true" label="New User" class="w-auto"></Button>
      </div>

      <div class="card w-full">
        <div class="border-2 border-surface-200 dark:border-surface-600 border-surface mb-2 p-2 rounded-xl">
          <DataTable :value="users" scrollable rowHover>
            <Column field="first_name" :sortable="true">
              <template #header>
                <span class="font-semibold text-sm text-muted-color">Name</span>
              </template>
              <template #body="{data}">
                <div class="flex items-center gap-4">
                  <Avatar :label="data.id.toString()" styleClass="mr-2" size="large" shape="circle"></Avatar>
                  <div>
                    <p class="mt-0 mb-2 font-medium text-lg text-color-primary">{{ data.first_name }}</p>
                    <p class="mt-0 mb-2 font-normal text-base text-muted-color">{{ data.last_name }}</p>
                  </div>
                </div>
              </template>
            </Column>
            <Column expander>
              <template #header>
                <span class="font-semibold text-sm text-muted-color">Email / Phone</span>
              </template>
              <template #body="{data}">
                <p class="mt-0 mb-2 font-normal text-sm text-color-primary">{{ data.email }}</p>
                <p class="mt-0 mb-2 font-normal text-sm text-muted-color">{{ data.phone }}</p>
              </template>
            </Column>
            <Column field="disabled" :sortable="true">
              <template #header>
                <span class="font-semibold text-sm text-muted-color">Status</span>
              </template>
              <template #body="{data}">
                <p class="mt-0 mb-2 font-normal text-base text-muted-color">
                  <Tag v-if="data.disabled" severity="danger" value="Disabled" rounded></Tag>
                  <Tag class="px-4" v-else value="Active" severity="success" rounded></Tag>
                </p>
              </template>
            </Column>
            <Column>
              <template #header>
                <span class="font-semibold text-sm text-muted-color">Status</span>
              </template>
              <template #body>
                <Button type="button" icon="pi pi-ellipsis-v" class="p-button-text p-button-secondary"
                        @click="$refs.menu.toggle($event)"></Button>
                <Menu ref="menu" appendTo="body" popup :model="items"></Menu>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </section>
  </section>

  <div v-show="activeTab === 1" class="card mt-6">
    <div class="border-2 border-surface-200 dark:border-surface-600 border-surface mb-2 p-2 rounded-xl">
    <DataTable :value="trainEventSubscriptions" paginator :rows="10" :rows-per-page-options="[10, 25, 50, 100]" sortField="id" :sortOrder="-1" tableStyle="min-width: 50rem">
      <Column field="id" header="ID" :sortable="true"></Column>
      <Column field="username" header="Username" :sortable="true"></Column>
      <Column field="unit_number" header="Unit" :sortable="true"></Column>
      <Column field="call_made" header="Call Made" :sortable="true">
        <template #body="slotProps">
          <Tag v-if="slotProps.data.call_made" value="True" severity="success" rounded></Tag>
          <Tag v-else severity="danger" value="False" rounded></Tag>


        </template>
      </Column>
      <Column field="event_station" header="Event Station" :sortable="true"></Column>
      <Column field="created_at" header="Created At" :sortable="true">
        <template #body="slotProps">
          {{new Date(slotProps.data.created_at).toLocaleString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hourCycle: 'h23'
        })}}
        </template>
      </Column>
      <Column field="call_time" header="Call Time" :sortable="true">
        <template #body="slotProps">
          {{slotProps?.data?.call_time ? new Date(slotProps.data.call_time).toLocaleString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hourCycle: 'h23'
        }) : null }}
        </template>
      </Column>
      <Column field="call_sid" header="Call SID"></Column>
      <Column field="message_sid" header="Message SID"></Column>
      <Column header="Actions">
        <template #body>
          <Button icon="pi pi-times" severity="danger" rounded outlined v-tooltip.top="'Cancel call'"></Button>
        </template>
      </Column>
    </DataTable>
    </div>
  </div>
  <NewUserDialog :visible="newUserDialogVisible" @visibleChange="newUserDialogVisible = false"></NewUserDialog>

</template>

<style scoped>

</style>