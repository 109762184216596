<script setup>
import {ref, onMounted, watch} from 'vue';

const unitWaybillAndLocationDialogVisible = defineModel('unitWaybillAndLocationDialogVisible');
const props = defineProps({unitInfo: Object})
import backendCall from "../../services/AxiosService.js";

const unitLocationInfo = ref();

const getUnitLocation = async (unitNumber) => {
  const queryResult = await backendCall.get(`${import.meta.env.VITE_API_BASE_URL}/units/cnquery?unit_number=${unitNumber}`, {withCredentials: true})

  // console.log(queryResult);
  unitLocationInfo.value = queryResult.data;
}

watch(() => props.unitInfo?.unit_number, (newUnitNumber) => {
  unitLocationInfo.value = null;
  if (newUnitNumber) {
    getUnitLocation(newUnitNumber);
  }
}, { immediate: true })

onMounted(() => {

})

</script>

<template>
  <Dialog v-model:visible="unitWaybillAndLocationDialogVisible" :modal="false" :closable="false" :showHeader="false"
          :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '45vw'}">
    <section class="flex flex-col w-full mt-6">
      <div class="flex w-full justify-between items-center mb-6">
        <span
            class="font-semibold text-base text-surface-600 dark:text-surface-200">Waybill # {{
            unitInfo?.waybill_id
          }} </span>
        <div>
          <span class="mx-4">
          <span class="font-medium text-base text-gray-500 dark:text-gray-300">
            SEQ:
          </span>
          <span class="font-medium text-base text-surface-600 dark:text-gray-200">
            {{ unitInfo.sequence }}
          </span>
            </span>
          <span><Button type="button" icon="pi pi-times text-sm"
                        class="w-8 h-8 p-button-rounded p-button-secondary p-button-text text-surface-600 dark:text-surface-200"
                        @click="unitWaybillAndLocationDialogVisible = false"></Button>
          </span>
        </div>
      </div>
      <div class="flex justify-between items-center w-full mb-6">
        <span class="font-semibold text-xl mt-0 mb-0 text-surface-900 dark:text-surface-0">
        <span>  {{ unitInfo?.unit_number }}  </span>         <span v-if="unitInfo?.is_priority"> <Tag
            class="ml-2 align-middle" value="Priority"
            severity="success"/> </span> </span>

        <!--        <Button type="button" icon="pi pi-pencil text-sm"-->
        <!--                class="w-8 h-8 p-button-rounded p-button-text bg-surface-200 dark:bg-surface-600 text-surface-600 dark:text-surface-200"></Button>-->
      </div>
      <table>
        <tr style="height:2.5rem">
          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Billed Setpoint:
            {{ unitInfo?.billed_setpoint }} {{ unitInfo?.billed_setpoint ? unitInfo.billed_setpoint_temp_unit : '' }}
          </td>
        </tr>
        <tr style="height:2.5rem">
          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Setpoint Celsius:
            {{ unitInfo?.complete_setpoint_c }}
          </td>
        </tr>
        <tr style="height:2.5rem">
          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Setpoint Fahrenheit:
            {{ unitInfo?.complete_setpoint_f }}
          </td>
        </tr>
        <tr style="height:2.5rem">
          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Clip-On: {{ unitInfo?.clip_on }}</td>

        </tr>
        <tr style="height:2.5rem">
          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Car #: {{ unitInfo?.railcar }}</td>
        </tr>
      </table>
      <div class="border-y border-surface py-6 my-6">
        <p class="font-medium text-lg text-surface-700 dark:text-surface-100 mt-0">Notes</p>
        <p class="text-base text-surface-900 dark:text-surface-0 mt-0 mb-0">{{ unitInfo?.notes }}</p>
      </div>
      <div class="flex flex-col">
        <div class="flex justify-between items-center w-full">
          <p class="font-medium text-lg text-surface-700 dark:text-surface-100 mt-0">Location</p>
<Tag v-if="unitLocationInfo?.[0]?.Event?.Location.ReportedBy === 'Train'">On Train</Tag>
          <Tag v-else-if="unitLocationInfo?.[0]?.Event?.Location.ReportedBy === 'Station'">At Station</Tag>

        </div>
        <Tag class="w-fit my-4" >

          {{unitLocationInfo?.[0]?.Event?.Description}} {{unitLocationInfo?.[0]?.Event?.Location?.Station}} {{unitLocationInfo?.[0]?.Event?.Location?.ProvState}} <span v-if="unitLocationInfo?.[0]?.Event"> @ </span> {{unitLocationInfo?.[0]?.Event?.Time}}
        </Tag>
        <Tag class="w-fit" v-if="unitLocationInfo?.[0]?.Event?.Location?.ReportedId">{{ unitLocationInfo?.[0]?.Event?.Location?.ReportedId }}</Tag>
      </div>
    </section>
    <template #footer>
      <div class="flex border-t pt-8 border-surface justify-end items-center">
        <Button @click="unitWaybillAndLocationDialogVisible = false" icon="pi pi-times" label="Close"
                class="m-0"></Button>
      </div>
    </template>
  </Dialog>

</template>

<style scoped>

</style>