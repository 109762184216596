<template>
  <div class="flex justify-center">
    <Card class="flex content-center  border mt-2">

      <template #content>
        <Card >
          <template #title>
            <div class="text-center"> CN Unit Search</div>
          </template>
          <template #content>
            <form @submit.prevent="submitQuery">
            <div class="flex justify-center">
              <InputText class="text-center mt-6 mb-2" id="unitsearch" placeholder="Unit Number" v-model="value"
                         aria-describedby="unit-search"/>
            </div>

            <div class="flex gap-4 mt-1 justify-center">
              <Button outlined raised label="Submit" type="submit" class="px-20"/>
            </div>
            </form>

            <!--          <div class="bg-surface-0 dark:bg-surface-900 p-6 shadow rounded-border">-->
            <!--            <div v-if="unitInfo" class="font-medium text-3xl text-surface-900 dark:text-surface-0 mb-4">{{ unitInfo[0]?.EquipmentId }}</div>-->
            <!--            <div class="text-surface-500 dark:text-surface-300 mb-8">Egestas sed tempus urna et pharetra pharetra massa massa ultricies.</div>-->
            <!--            <ul class="list-none p-0 m-0 border-t border-surface">-->
            <!--              <li class="flex items-center py-4 px-2 flex-wrap bg-surface-50 dark:bg-surface-800">-->
            <!--                <div class="text-surface-500 dark:text-surface-300 w-full md:w-2/12 font-medium">Name</div>-->
            <!--                <div class="text-surface-900 dark:text-surface-0 w-full md:w-10/12">Elliot Alderson</div>-->
            <!--              </li>-->
            <!--              <li class="flex items-center py-4 px-2 flex-wrap">-->
            <!--                <div class="text-surface-500 dark:text-surface-300 w-full md:w-2/12 font-medium">Bio</div>-->
            <!--                <div class="text-surface-900 dark:text-surface-0 w-full md:w-10/12 leading-normal">Faucibus pulvinar elementum integer enim neque volutpat ac tincidunt vitae. Commodo odio aenean sed adipiscing diam donec adipiscing tristique. Eget felis eget nunc lobortis mattis aliquam faucibus purus in.</div>-->
            <!--              </li>-->
            <!--              <li class="flex items-center py-4 px-2 flex-wrap bg-surface-50 dark:bg-surface-800">-->
            <!--                <div class="text-surface-500 dark:text-surface-300 w-full md:w-2/12 font-medium">Salary Expectation</div>-->
            <!--                <div class="text-surface-900 dark:text-surface-0 w-full md:w-10/12">$150,000</div>-->
            <!--              </li>-->
            <!--              <li class="flex items-center py-4 px-2 flex-wrap">-->
            <!--                <div class="text-surface-500 dark:text-surface-300 w-full md:w-2/12 font-medium">Skills</div>-->
            <!--                <div class="text-surface-900 dark:text-surface-0 w-full md:w-10/12">-->
            <!--                  <Tag class="mr-2" value="Algorithms" :rounded="true"></Tag>-->
            <!--                  <Tag class="mr-2" severity="success" value="Javascript" :rounded="true"></Tag>-->
            <!--                  <Tag class="mr-2" severity="danger" value="Python" :rounded="true"></Tag>-->
            <!--                  <Tag severity="warning" value="SQL" :rounded="true"></Tag>-->
            <!--                </div>-->
            <!--              </li>-->
            <!--              <li class="flex items-center py-4 px-2 flex-wrap bg-surface-50 dark:bg-surface-800">-->
            <!--                <div class="text-surface-500 dark:text-surface-300 w-full md:w-2/12 font-medium">Repositories</div>-->
            <!--                <div class="text-surface-900 dark:text-surface-0 w-full md:w-10/12">-->
            <!--                  <div class="grid grid-cols-12 gap-4 mt-0 mr-0">-->
            <!--                    <div class="col-span-12 md:col-span-6">-->
            <!--                      <div class="p-4 border border-surface rounded-border bg-surface-0 dark:bg-surface-900">-->
            <!--                        <div class="text-surface-900 dark:text-surface-0 mb-2">-->
            <!--                          <i class="pi pi-github mr-2"></i>-->
            <!--                          <span class="font-medium">PrimeFaces</span>-->
            <!--                        </div>-->
            <!--                        <div class="text-surface-700 dark:text-surface-100">Ultimate UI Component Suite for JavaServer Faces</div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                    <div class="col-span-12 md:col-span-6">-->
            <!--                      <div class="p-4 border border-surface rounded-border bg-surface-0 dark:bg-surface-900">-->
            <!--                        <div class="text-surface-900 dark:text-surface-0 mb-2">-->
            <!--                          <i class="pi pi-github mr-2"></i>-->
            <!--                          <span class="font-medium">PrimeNG</span>-->
            <!--                        </div>-->
            <!--                        <div class="text-surface-700 dark:text-surface-100">The Most Complete Angular UI Component Library</div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                    <div class="col-span-12 md:col-span-6">-->
            <!--                      <div class="p-4 border border-surface rounded-border bg-surface-0 dark:bg-surface-900">-->
            <!--                        <div class="text-surface-900 dark:text-surface-0 mb-2">-->
            <!--                          <i class="pi pi-github mr-2"></i>-->
            <!--                          <span class="font-medium">PrimeReact</span>-->
            <!--                        </div>-->
            <!--                        <div class="text-surface-700 dark:text-surface-100">Advanced UI Components for ReactJS</div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                    <div class="col-span-12 md:col-span-6">-->
            <!--                      <div class="p-4 border border-surface rounded-border bg-surface-0 dark:bg-surface-900">-->
            <!--                        <div class="text-surface-900 dark:text-surface-0 mb-2">-->
            <!--                          <i class="pi pi-github mr-2"></i>-->
            <!--                          <span class="font-medium">PrimeVue</span>-->
            <!--                        </div>-->
            <!--                        <div class="text-surface-700 dark:text-surface-100">The Most Powerful Vue UI Component Library</div>-->
            <!--                      </div>-->
            <!--                    </div>-->

            <!--                  </div>-->
            <!--                </div>-->
            <!--              </li>-->
            <!--            </ul>-->
            <!--          </div>-->
            <div v-if="unitInfo" class="flex flex-col mt-2">
              <div class="flex flex-col md:flex-row">
                <Card class="m-2 border ">
                  <template #content>
                    <div>
                     <span class="text-surface-500 dark:text-surface-300"> Unit Number:</span> {{ unitInfo[0]?.EquipmentId }}
                    </div>
                    <div>
                      <span class="text-surface-500 dark:text-surface-300"> Waybill Status:</span> {{ unitInfo[0]?.WaybillStatus }}
                    </div>
                    <div>
                      <span class="text-surface-500 dark:text-surface-300"> Loaded Or Empty: </span> {{ unitInfo[0]?.LoadEmpty }}
                    </div>
                    <div>
                      <span class="text-surface-500 dark:text-surface-300"> Destination Station: </span> {{ unitInfo[0]?.Destination.Station }}
                    </div>
                    <div>
                      <span class="text-surface-500 dark:text-surface-300"> Destination City: </span> {{ unitInfo[0]?.Destination.City }}
                    </div>
                    <div>
                      <span class="text-surface-500 dark:text-surface-300"> Destination Prov / State: </span> {{ unitInfo[0]?.Destination.ProvState }}
                    </div>
                    <div>
                      <span class="text-surface-500 dark:text-surface-300"> Destination Country: </span> {{ unitInfo[0]?.Destination.CountryCode }}
                    </div>
                  </template>
                </Card>

                <Card class="m-2 border ">
                  <template #content>
                    <div>
                      <span class="text-surface-500 dark:text-surface-300"> Last Event Time: </span> {{ unitInfo[0]?.Event.Time }}
                    </div>
                    <div>
                      <span class="text-surface-500 dark:text-surface-300"> Last Event: </span> {{ unitInfo[0]?.Event.Description }}
                    </div>
                    <div>
                      <span class="text-surface-500 dark:text-surface-300"> Last Event Station: </span> {{ unitInfo[0]?.Event.Location.Station }}
                    </div>
                    <div>
                      <span class="text-surface-500 dark:text-surface-300"> Last Event Prov / State : </span> {{ unitInfo[0]?.Event.Location.ProvState }}
                    </div>
                    <div>
                      <span class="text-surface-500 dark:text-surface-300"> Last Event Country: </span> {{ unitInfo[0]?.Event.Location.CountryCode }}
                    </div>
                  </template>
                </Card>
              </div>
              <Card class="m-2 border ">
                <template #content>
                  <div>
                    <span class="text-surface-500 dark:text-surface-300"> Train, Truck or Station : </span> {{ unitInfo[0]?.Event.Location.ReportedBy }}
                  </div>
                  <div v-if="unitInfo[0]?.Event.Location.ReportedBy">
                    <span class="text-surface-500 dark:text-surface-300"> {{ unitInfo[0]?.Event.Location.ReportedBy }} </span> : {{ unitInfo[0]?.Event.Location.ReportedId }}
                  </div>
                    <div>
                      <span class="text-surface-500 dark:text-surface-300">Lot: </span> {{ unitInfo[0]?.LotLocation?.Lot }} <span class="text-surface-500 dark:text-surface-300"> Row: </span> {{ unitInfo[0]?.LotLocation?.Row }}
                      <div v-if="unitInfo[0]?.LotLocation?.Spot"> Spot: {{ unitInfo[0]?.LotLocation.Spot }}</div>
                    </div>
                    <div>
                      <span class="text-surface-500 dark:text-surface-300"> Car #: </span> {{ unitInfo[0]?.FlatcarEquipmentId }}
                    </div>
                    <div>
                      <span class="text-surface-500 dark:text-surface-300"> Chassis #: </span> {{ unitInfo[0]?.ChassisEquipmentId }}
                    </div>
                    <div>
                      <span class="text-surface-500 dark:text-surface-300"> Unit Type: </span> {{ unitInfo[0]?.CarKind }}
                    </div>
                    <div>
                      <span class="text-surface-500 dark:text-surface-300"> Unit Type Description: </span> {{ unitInfo[0]?.CarKindDescription }}
                    </div>
                </template>
              </Card>

            </div>

          </template>
        </Card>


      </template>
      <template #footer>

      </template>
    </Card>
  </div>
</template>

<script setup>
import {ref} from "vue";
import {useAuthStore} from "../stores/authStore.js";
import backendCall from "../services/AxiosService.js";

const value = ref(null);
const unitInfo = ref(null);
const authStore = useAuthStore();

const submitQuery = async () => {
  const queryResult = await backendCall.get(`${import.meta.env.VITE_API_BASE_URL}/units/cnquery?unit_number=${value.value}`, {withCredentials: true})

  // console.log(queryResult);
  unitInfo.value = await queryResult.data;
}
</script>
