<script setup>
import { ref } from "vue";
import {useRequestsStore} from "../../stores/requestStore.js";
import ConfirmPopup from "primevue/confirmpopup";

const requestsStore = useRequestsStore();
const NewRequestModalVisible = ref(false);
import NewRequestModal from "../../components/requests/NewRequestModal.vue";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import backendCall from "../../services/AxiosService.js";


const confirm = useConfirm();
const toast = useToast();


const confirm1 = (requestId ,event) => {
  confirm.require({
    target: event.currentTarget,
    message: 'Are you sure you want to delete this request?',
    icon: 'pi pi-info-circle',
    rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
    acceptClass: 'p-button-danger p-button-sm',
    rejectLabel: 'Cancel',
    acceptLabel: 'Delete',
    accept: async () => {
      try {
        const deleteRequestCall = await backendCall.delete(`requests/${requestId}`)
        if (deleteRequestCall.error) {
          throw new Error('API Call Error')
        }
        toast.add({severity: 'success', summary: 'Confirmed', detail: `Request ${requestId} Deleted`, life: 3000});
      } catch (e) {
        toast.add({ severity: 'error', summary: 'Failed', detail: `Failed to remove request ${requestId}`, life: 3000 });
      }
      },
    reject: () => {
    }
  });
};
</script>

<template>
  <div class="bg-surface-0 dark:bg-surface-900 p-6 shadow rounded-border">
    <div class="mb-4 flex items-center justify-between">
      <span class="text-xl font-medium text-surface-900 dark:text-surface-0">Requests</span>
      <div class="flex gap-4">
      <Button label="New" icon="pi pi-plus" @click="NewRequestModalVisible = true"></Button>
        <RouterLink :to="{name: 'requestPrintConfiguration'}">
      <Button label="Print" severity="info" icon="pi pi-print"></Button>
        </RouterLink>
      </div>
    </div>
    <div class="border-2 border-dashed border-surface">
      <DataTable :value="requestsStore.requests">
        <Column field="request_id" header="REQ ID"></Column>
        <Column field="equipment_id" header="Unit"></Column>
        <Column field="request_billed_setpoint" header="Setpoint"></Column>
        <Column field="request_notes" header="Description"></Column>
        <Column header="actions">
          <template #body="slotProps">
            <div class="flex justify-around">
            <Button icon="pi pi-check" severity="success" outlined rounded aria-label="Submit-Info" />
            <Button @click="confirm1(slotProps.data.request_id,$event)" icon="pi pi-times" severity="danger" outlined rounded aria-label="Cancel" />
            </div>
          </template>
        </Column>
      </DataTable>
      <NewRequestModal v-bind:visible="NewRequestModalVisible" @visibleChange="NewRequestModalVisible = false" ></NewRequestModal>
    </div>
  </div>

  <ConfirmPopup></ConfirmPopup>

</template>

<style scoped>

</style>