<script setup lang="ts">
import {useAuthStore} from "../../stores/authStore";

const authStore = useAuthStore();

import router from "../../router/index.js";
import backendCall from "../../services/AxiosService.js";
import {onMounted, ref} from "vue";
import ElectricCheckCards from "./ElectricCheckCards.vue";
import RequestCheckCards from "./RequestCheckCards.vue";
import DomesticCheckCards from "./DomesticCheckCards.vue";
import {useToast} from "primevue/usetoast";
import {createThemeHandler} from '../../utilities/themeHandler.ts'
import { useGeolocation } from '@vueuse/core'

const { coords, locatedAt, error, resume, pause } = useGeolocation()

const { isDarkMode } = createThemeHandler(authStore)


const electrics = ref([]);
const domestics = ref([]);
const requests = ref([]);
const trainId = ref(null);
const activeTab = ref(0);
const toast = useToast();

const trainArrived = ref(false);
const waitTimeStarted = ref(false);
const timeStarted = ref(false);
const protectionStarted = ref(false);
const protectionEnded = ref(false);
const protectionTrack = ref(null);
const trainComplete = ref(false);

function handleThemeChange(value) {
  isDarkMode.value = value
}

const toggleTrainArrived = () => {
  trainArrived.value = !trainArrived.value;
}

const toggleBeginTime = () => {
  timeStarted.value = !timeStarted.value;
}

const toggleWaitTime = () => {
  waitTimeStarted.value = !waitTimeStarted.value;
}

const endProtection = () => {
  protectionStarted.value = false;
  protectionEnded.value = true;
  protectionTrack.value = null;
}

const startProtection = () => {
  protectionStarted.value = true;
  protectionEnded.value = false;
}

const props = defineProps({trainid: String});

const getTrainUnits = async () => {

  await backendCall.get(`${import.meta.env.VITE_API_BASE_URL}trains/${props.trainid}/units`)
      .then(response => {

        return response;
      })
      .then(data => {
        trainId.value = data?.data.data.train_id_formatted;
        electrics.value = data?.data.data.electrics;
        domestics.value = data?.data.data.domestics;
        requests.value = data?.data.data.requests;
        console.log(data)

      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });

}

// Initialize as an empty array
const alarmSuggestions = ref<string[]>([]);
const allAlarms = ref<string[]>([]);

// Modify fetchAlarmList to update alarmSuggestions
const fetchAlarmList = async () => {
  try {
    const fetchAlarmListCall = await backendCall.get(`units/all-alarms`);
    allAlarms.value = [...fetchAlarmListCall.data.data];
    // Update alarmSuggestions here
    alarmSuggestions.value = [...allAlarms.value];
    console.log(allAlarms.value);
  } catch (error) {
    console.error("Error fetching alarm list:", error);
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Failed to fetch alarm list',
      life: 3000
    });
  }
}


const submitUnitInfoToServer = async (data) => {
  data.trainId = parseInt(props.trainid);
  await backendCall.post('unit-check', data);
  toast.add({severity: 'success', summary: 'Success', detail: data, life: 3000});


}

onMounted( async() => {
  getTrainUnits()
  await fetchAlarmList()
})


</script>

<template>
  <div class="flex flex-col h-screen">
    <div class="bg-surface-0 dark:bg-surface-950 px-6 py-8 md:px-12 lg:px-20">
      <div class="flex md:items-center justify-between md:flex-row">
        <div>
          <Button @click="router.push('/trains')" label="Back" class="p-button mr-2" icon="pi pi-arrow-left"></Button>
        </div>
        <div class="font-medium text-3xl text-surface-900 dark:text-surface-0">{{ trainId }}</div>
        <div class="mt-4 md:mt-0">
<span class="flex mr-2">
            <i class="pi pi-sun mr-1 mt-1"></i>
            <input-switch v-if="authStore" class="flex p-3 lg:px-3 py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer
                        transition-duration-150 transition-colors p-ripple" id="darkmode-switch"
                          v-model="isDarkMode"></input-switch>
          <i class="pi pi-moon ml-1 mt-1"></i>
            </span>
        </div>
      </div>
    </div>
    <div class="my-6">
      <ul class="bg-surface-0 dark:bg-surface-900 p-0 m-0 list-none flex overflow-x-auto select-none">
        <li class="flex-1 flex justify-center">
          <a v-ripple
             class="cursor-pointer px-6 py-4 flex items-center justify-center border-b-2 hover:border-surface-500 dark:hover:border-surface-300 transition-colors duration-150 p-ripple"
             :class="{'border-primary-500 text-primary-500 hover:border-primary-500': activeTab === 0, 'text-700 border-transparent': activeTab !== 0}"
             @click="activeTab = 0">
            <Badge class="mr-3" :value="electrics.length"/>
            <span class="font-medium">Electrics</span>
          </a>
        </li>
        <li class="flex-1 flex justify-center">
          <a v-ripple
             class="cursor-pointer px-6 py-4 flex items-center justify-center border-b-2 hover:border-surface-500 dark:hover:border-surface-300 transition-colors duration-150 p-ripple"
             :class="{'border-primary-500 text-primary-500 hover:border-primary-500': activeTab === 1, 'text-700 border-transparent': activeTab !== 1}"
             @click="activeTab = 1">
            <Badge class="mr-3" :value="requests.length"/>
            <span class="font-medium">Requests</span>
          </a>
        </li>
        <li class="flex-1 flex justify-center">
          <a v-ripple
             class="cursor-pointer px-6 py-4 flex items-center justify-center border-b-2 hover:border-surface-500 dark:hover:border-surface-300 transition-colors duration-150 p-ripple"
             :class="{'border-primary-500 text-primary-500 hover:border-primary-500': activeTab === 2, 'text-700 border-transparent': activeTab !== 2}"
             @click="activeTab = 2">
            <Badge class="mr-3" :value="domestics.length"/>
            <span class="font-medium">Domestics</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="overflow-y-auto mx-6">
      <div v-show="activeTab === 0" class="col-span-12">
        <div>
          <ElectricCheckCards :electrics="electrics" :alarms="allAlarms" @submit-unit-info="submitUnitInfoToServer"/>
        </div>
      </div>

      <div v-show="activeTab === 1" class="col-span-12">
        <div>
          <RequestCheckCards :requests="requests" :alarms="allAlarms" @submit-unit-info="submitUnitInfoToServer"/>
        </div>
      </div>

      <div v-show="activeTab === 2" class="col-span-12">
        <div>
          <DomesticCheckCards :domestics="domestics" :alarms="allAlarms" @submit-unit-info="submitUnitInfoToServer"/>
        </div>
      </div>

    </div>
  </div>
  <Button class="time-button flex items-center"
          v-styleclass="{ selector: '#slideover-8', enterFromClass: 'hidden', enterActiveClass: 'my-fadein', leaveActiveClass: 'my-fadeout', leaveToClass: 'hidden', hideOnOutsideClick: true }">
    <i class="pi pi-clock"></i>
  </Button>
  <!--  Time Sidebar-->

  <div id="slideover-8"
       class="hidden bg-white dark:bg-black z-50 bg-surface-0 h-full absolute top-0 right-0 shadow w-72"
       style="z-index: 101">
    <div class="flex flex-col h-full">
      <div class="flex items-center justify-between mb-6 p-6">
        <span class="text-surface-900 dark:text-surface-0 text-xl font-medium">Time Tracking</span>
        <Button icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain"
                v-styleclass="{ selector: '#slideover-8', enterFromClass: 'hidden', enterActiveClass: 'my-fadein', leaveActiveClass: 'my-fadeout', leaveToClass: 'hidden', hideOnOutsideClick: true }"></Button>
      </div>
      <div class="flex-auto overflow-y-auto">
        <ul class="list-none p-0 m-0">
          <li class="flex items-center border-t border-surface p-4">
                         <span v-if="timeStarted"
                               class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                               style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span v-else
                  class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                  style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span class="text-surface-700 dark:text-surface-100 font-medium">
            <Button @click="toggleBeginTime" label="Begin Time" text outlined></Button>
            </span>
          </li>
          <li class="flex items-center border-t border-surface p-4">
                          <span v-if="waitTimeStarted"
                                class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                                style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span v-else
                  class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                  style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span class="text-surface-700 dark:text-surface-100 font-medium">
              <Button @click="toggleWaitTime" label="Start Wait Time" text outlined></Button>
            </span>
          </li>
          <li class="flex items-center border-t border-surface p-4">
                    <span v-if="trainArrived"
                          class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                          style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span v-else
                  class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                  style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span class="text-surface-700 dark:text-surface-100 font-medium">
              <Button @click="toggleTrainArrived" label="Train Arrived" text outlined></Button>
            </span>
          </li>
          <li class="flex flex-col justify-evenly border-t border-surface p-4">
            <div>
              <InputText id="track-input" v-model="protectionTrack" :disabled="protectionStarted"
                         placeholder="Track"></InputText>
            </div>
            <div class="mt-2">
                    <span v-if="protectionStarted"
                          class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                          style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
              <span v-else
                    class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                    style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
              <span class="text-surface-700 dark:text-surface-100 font-medium">
              <Button @click="startProtection" :disabled="!protectionTrack" label="Protection Start" text
                      outlined></Button>
            </span>
            </div>
          </li>
          <li class="flex items-center border-t border-surface p-4">
                    <span v-if="protectionEnded"
                          class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                          style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span v-else
                  class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                  style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span class="text-surface-700 dark:text-surface-100 font-medium">
              <Button @click="endProtection" label="Protection End" text outlined></Button>
            </span>
          </li>
          <li class="flex items-center border-t border-surface p-4">
                    <span v-if="trainComplete"
                          class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                          style="width:35px;height:35px">
                        <i class="pi pi-thumbs-up"></i>
                    </span>
            <span v-else
                  class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent"
                  style="width:35px;height:35px">
                        <i class="pi pi-thumbs-up"></i>
                    </span>
            <span class="text-surface-700 dark:text-surface-100 font-medium">
              <Button @click="trainComplete = true" label="Train Complete" text outlined></Button>
            </span>
          </li>

          <!--          <li class="flex items-center border-t border-surface p-4">-->
          <!--                    <span class="rounded-full text-2xl inline-flex items-center justify-center mr-4 border border-surface" style="width:35px;height:35px">-->
          <!--                        <i class="pi pi-thumbs-up text-surface-700 dark:text-surface-100"></i>-->
          <!--                    </span>-->
          <!--            <span class="text-surface-700 dark:text-surface-100 font-medium">Confirmation</span>-->
          <!--          </li>-->
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>

.time-button {
  display: block;
  position: fixed !important;
  width: 52px;
  height: 52px;
  line-height: 52px;
  background-color: var(--primary-color);
  text-align: center;
  color: var(--primary-color-text);
  top: 50%;
  right: 0;
  z-index: 100;
  overflow: hidden;
  cursor: pointer;
  outline: 0 none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

@keyframes my-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes my-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.my-hidden {
  display: none;
}

.my-fadein {
  animation: my-fadein 150ms linear;
}

.my-fadeout {
  animation: my-fadeout 150ms linear;
}
</style>