<script setup>
const infoVisible =  defineModel();
const props = defineProps({unitNumber: String, description: String, isRequest: Boolean})
</script>

<template>
  <Dialog v-model:visible="infoVisible" :modal="false" :closable="false" :showHeader="false" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '45vw'}">
    <section class="flex flex-col w-full mt-6">
      <div class="flex w-full justify-between items-center mb-6">
        <span class="font-semibold text-base text-surface-600 dark:text-surface-200">Notes / <span class="text-surface-900 dark:text-surface-0">Daily</span></span>
        <Button type="button" icon="pi pi-times text-sm" class="w-8 h-8 p-button-rounded p-button-secondary p-button-text text-surface-600 dark:text-surface-200" @click="infoVisible = false"></Button>
      </div>
      <div class="flex justify-between items-center w-full mb-6">
        <p class="font-semibold text-xl mt-0 mb-0 text-surface-900 dark:text-surface-0">{{ unitNumber }}</p>
        <Button type="button" icon="pi pi-pencil text-sm" class="w-8 h-8 p-button-rounded p-button-text bg-surface-200 dark:bg-surface-600 text-surface-600 dark:text-surface-200"></Button>
      </div>
<!--      <table>-->
<!--        <tr style="height:2.5rem">-->
<!--          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Status</td>-->
<!--          <td class="font-medium text-base text-surface-900 dark:text-surface-0">In Progress</td>-->
<!--        </tr>-->
<!--        <tr style="height:2.5rem">-->
<!--          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Assignee</td>-->
<!--          <td>-->
<!--            <div class="flex items-center">-->
<!--              <img src="/images/blocks/avatars/circle/avatar-m-11.png" alt="Image" class="inline mr-2" style="height: 1.5rem; width: 1.5rem;" />-->
<!--              <span class="font-medium text-base text-surface-900 dark:text-surface-0">John Walter</span>-->
<!--            </div>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr style="height:2.5rem">-->
<!--          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Due Date</td>-->
<!--          <td class="font-medium text-base text-surface-900 dark:text-surface-0">Oct 21 at 2:00 PM</td>-->
<!--        </tr>-->
<!--        <tr style="height:2.5rem">-->
<!--          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Label</td>-->
<!--          <td>-->
<!--            <span class="p-tag bg-orange-500 mr-2">Product</span>-->
<!--            <span class="p-tag bg-green-500">Quality</span>-->
<!--          </td>-->
<!--        </tr>-->
<!--      </table>-->
      <div class="border-y border-surface py-6 my-6">
        <p v-if="isRequest" class="font-medium text-lg text-surface-700 dark:text-surface-100 mt-0">Request Description</p>
        <p v-else class="font-medium text-lg text-surface-700 dark:text-surface-100 mt-0">Description</p>
        <p class="text-base text-surface-900 dark:text-surface-0 mt-0 mb-0">{{ description }}</p>
      </div>
<!--      <div class="flex flex-col">-->
<!--        <div class="flex justify-between items-center w-full">-->
<!--          <p class="font-medium text-lg text-surface-700 dark:text-surface-100 mt-0">Checklist</p>-->
<!--          <span class="text-surface-500 dark:text-surface-300 flex items-center"><i class="pi pi-check-square text-lg mr-2"></i>1/4</span>-->
<!--        </div>-->
<!--        <div class="field-checkbox">-->
<!--          <Checkbox v-model="checked1" binary inputId="binary1"></Checkbox>-->
<!--          <label for="binary">Placerat in egestas erat imperdiet.</label>-->
<!--        </div>-->
<!--        <div class="field-checkbox">-->
<!--          <Checkbox v-model="checked2" binary inputId="binary2"></Checkbox>-->
<!--          <label for="binary">Cursus euismod quis viverra nibh.</label>-->
<!--        </div>-->
<!--        <div class="field-checkbox">-->
<!--          <Checkbox v-model="checked3" binary inputId="binary3"></Checkbox>-->
<!--          <label for="binary">Malesuada fames ac turpis egestas integer eget.</label>-->
<!--        </div>-->
<!--        <div class="field-checkbox mb-0">-->
<!--          <Checkbox v-model="checked4" binary inputId="binary4"></Checkbox>-->
<!--          <label for="binary">Nisl suscipit adipiscing bibendum est ultricies integer quis.</label>-->
<!--        </div>-->
<!--      </div>-->
    </section>
    <template #footer>
      <div class="flex border-t pt-8 border-surface justify-end items-center">
        <Button @click="infoVisible = false" icon="pi pi-times" label="Close" class="m-0"></Button>
      </div>
    </template>
  </Dialog>

</template>

<style scoped>

</style>