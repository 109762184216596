<template>
  <Card>
    <template #header>
<h3 class="text-center">{{headerTitle}}</h3>
      <div class="flex justify-end">
        <a :href="`https://rms.janssenequipment.com/trains/${$route.params.trainid}/generatedieselpdf`">
          <Button class="mr-6" icon="pi pi-print" severity="success" aria-label="Print" outlined/>
        </a>
      </div>
    </template>
    <template #content>
    <DataTable show-gridlines striped-rows size="small" :value="units">
      <Column field="sequence" header="SEQ" :sortable="true" style="width: 3rem;" class="text-center text-sm"></Column>
      <Column field="unit_number" header="Unit" :sortable="true" style="width: 7rem;" class="text-center text-sm">
        <template #body="slotProps">
          <Button @click="openUnitWaybillAndLocationDialog(slotProps.data)" text size="small">
            <div class="text-sm"> {{ slotProps.data.unit_number }} </div>
          </Button>
        </template>
      </Column>
      <Column field="complete_setpoint_f" header="Setpoint" :sortable="true" style="width: 3rem;" class="text-right text-sm"></Column>
      <Column field="unit_type" header="Type" :sortable="true" style="width: 3rem;" class="text-center text-sm"></Column>
      <Column field="railcar" header="Car" :sortable="true" style="width: 5rem;" class="text-center text-sm"></Column>
      <Column v-if="props.detailLevel === 'detailed'" field="destination" header="Destination" :sortable="true" style="width: 5rem;" class="text-center"></Column>
      <Column style="width: 2rem;">
        <template #body="slotProps">
          <ConfirmPopup></ConfirmPopup>
          <div class="flex justify-center">
            <Button  @click="$emit('open-subscribe-dialog', slotProps.data)" class="mx-1 p-0 my-0"  severity="warning" text rounded aria-label="Notify">
              <i class="pi pi-bell" aria-label="Notify"/>
            </Button>
<!--            <Button @click="confirm1($event)" size="small" class="p-0 my-0" severity="danger" text rounded aria-label="Cancel">-->
<!--              <i class="pi pi-times" aria-label="Delete"/>-->
<!--            </Button>-->
          </div>
        </template>
      </Column>

    </DataTable>
    </template>
  </Card>

  <UnitWaybillAndLocationDialog :unit-info="selectedUnitInfo"  v-model:unit-waybill-and-location-dialog-visible="unitWaybillAndLocationDialogVisible"/>

</template>

<script setup>
import {onMounted, ref} from 'vue';
import ConfirmPopup from "primevue/confirmpopup";
import UnitWaybillAndLocationDialog from "../../waybill/UnitWaybillAndLocationDialog.vue";

// const units = ref();
const props = defineProps({
  headerTitle: String,
  units: Object,
  detailLevel: String
})

const unitWaybillAndLocationDialogVisible = ref(false);
const selectedUnitInfo = ref();

const openUnitWaybillAndLocationDialog = (data) => {
  unitWaybillAndLocationDialogVisible.value = true;
  selectedUnitInfo.value = data;
}

const emit = defineEmits(['open-subscribe-dialog']);



onMounted(() => {

});



</script>
