<script setup>
import {computed} from 'vue';
const refuelInfoDialogVisible = defineModel('refuelInfoDialogVisible')
const props = defineProps({refuelInfo: Object })
import RefuelMap from "./RefuelMap.vue";


const eventTimeLocaleString = computed(() => {
  if (props.refuelInfo && props.refuelInfo.date_time) {
    return new Date(props.refuelInfo.date_time).toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hourCycle: 'h23'
    });
  }
  return '';
});

</script>

<template>
  <Dialog v-if="props.refuelInfo" v-model:visible="refuelInfoDialogVisible" :modal="false" :closable="false" :showHeader="false"
          :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '45vw'}">
    <section class="flex flex-col w-full mt-6">
      <div class="flex w-full justify-between items-center mb-6">
        <span class="font-semibold text-base text-surface-600 dark:text-surface-200">Refuel <span class="text-surface-900 dark:text-surface-0">Info</span></span>
        <Button type="button" icon="pi pi-times text-sm"
                class="w-8 h-8 p-button-rounded p-button-secondary p-button-text text-surface-600 dark:text-surface-200"
                @click="refuelInfoDialogVisible = false"></Button>
      </div>
      <div class="flex justify-between items-center w-full mb-6">
        <p class="font-semibold text-xl mt-0 mb-0 text-surface-900 dark:text-surface-0">
          {{props.refuelInfo.equipment_id}}</p>
<!--        <Button type="button" icon="pi pi-pencil text-sm"-->
<!--                class="w-8 h-8 p-button-rounded p-button-text bg-surface-200 dark:bg-surface-600 text-surface-600 dark:text-surface-200"></Button>-->
      </div>
      <table>
        <tr style="height:2.5rem">
          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Time</td>
          <td class="font-medium text-base text-surface-900 dark:text-surface-0">{{ eventTimeLocaleString }}</td>
        </tr>
        <tr style="height:2.5rem">
          <td class="font-medium text-base text-surface-600 dark:text-surface-200">ID</td>
          <td class="font-medium text-base text-surface-900 dark:text-surface-0">{{props.refuelInfo.refuel_id}}</td>
        </tr>
        <tr style="height:2.5rem">
          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Start Fuel Level</td>
          <td class="font-medium text-base text-surface-900 dark:text-surface-0">{{props.refuelInfo.start_fl}}</td>
        </tr>
        <tr style="height:2.5rem">
          <td class="font-medium text-base text-surface-600 dark:text-surface-200">End Fuel Level</td>
          <td class="font-medium text-base text-surface-900 dark:text-surface-0">{{props.refuelInfo.end_fl}}</td>
        </tr>
        <tr style="height:2.5rem">
          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Fuel Added</td>
          <td class="font-medium text-base text-surface-900 dark:text-surface-0"><span v-if="props.refuelInfo.fuel_added">{{props.refuelInfo.fuel_added}}L</span></td>
        </tr>
        <tr style="height:2.5rem">
          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Employee</td>
          <td>
            <div class="flex items-center">
<!--              <img src="/images/blocks/avatars/circle/avatar-m-11.png" alt="Image" class="inline mr-2"-->
<!--                   style="height: 1.5rem; width: 1.5rem;"/>-->
              <span class="font-medium text-base text-surface-900 dark:text-surface-0">{{props.refuelInfo.first_name}} {{props.refuelInfo.last_name}}</span>
            </div>
          </td>
        </tr>
        <tr style="height:2.5rem">
          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Truck</td>
          <td>
            <Avatar :label="props.refuelInfo.truck_id" shape="circle" style="background-color: #5ac55a" />
          </td>
        </tr>
<!--        <tr style="height:2.5rem">-->
<!--          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Status</td>-->
<!--          <td class="font-medium text-base text-surface-900 dark:text-surface-0">In Progress</td>-->
<!--        </tr>-->
<!--        <tr style="height:2.5rem">-->
<!--          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Assignee</td>-->
<!--          <td>-->
<!--            <div class="flex items-center">-->
<!--              <img src="/images/blocks/avatars/circle/avatar-m-11.png" alt="Image" class="inline mr-2"-->
<!--                   style="height: 1.5rem; width: 1.5rem;"/>-->
<!--              <span class="font-medium text-base text-surface-900 dark:text-surface-0">John Walter</span>-->
<!--            </div>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr style="height:2.5rem">-->
<!--          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Due Date</td>-->
<!--          <td class="font-medium text-base text-surface-900 dark:text-surface-0">Oct 21 at 2:00 PM</td>-->
<!--        </tr>-->
<!--        <tr style="height:2.5rem">-->
<!--          <td class="font-medium text-base text-surface-600 dark:text-surface-200">Label</td>-->
<!--          <td>-->
<!--            <span class="p-tag bg-orange-500 mr-2">Product</span>-->
<!--            <span class="p-tag bg-green-500">Quality</span>-->
<!--          </td>-->
<!--        </tr>-->
      </table>
      <div class="border-y border-surface py-6 my-6">
        <p class="font-medium text-lg text-surface-700 dark:text-surface-100 mt-0">Notes</p>
        <p class="text-base text-surface-900 dark:text-surface-0 mt-0 mb-0">{{props.refuelInfo.notes}}</p>
      </div>
      <div class="flex flex-col">
        <div class="flex justify-between items-center w-full">
          <p class="font-medium text-lg text-surface-700 dark:text-surface-100 mt-0">Map</p>
<!--          <span class="text-surface-500 dark:text-surface-300 flex items-center"><i class="pi pi-check-square text-lg mr-2"></i>1/4</span>-->
        </div>
<!--        Map Here-->
<!--        {{props.refuelInfo.truck_gps}}-->
        <RefuelMap v-if="props.refuelInfo" style="height: 300px" :coordinates="props.refuelInfo.truck_gps"></RefuelMap>
      </div>
    </section>
    <template #footer>
      <div class="flex border-t pt-8 border-surface justify-end items-center">
        <Button @click="refuelInfoDialogVisible = false" icon="pi pi-times" label="Close" class="m-0"></Button>
      </div>
    </template>
  </Dialog>

</template>

<style scoped>

</style>