<script setup>
import {ref} from "vue";
import backendCall from "../../services/AxiosService.js";
import {useToast} from "primevue/usetoast";
import { useTrainsStore } from "../../stores/trainsStore.js";


const props = defineProps({visible: Boolean});
const emit = defineEmits(['visible-change','fetch-trains']);
const toast = useToast();
const trainStore = useTrainsStore();

const originDepartureDate = ref();
const eta = ref();

const newTrainData = ref({
  trainId: null,
  eta: null,
  originDepartureDate: null
});

const submitNewTrain = async () => {
  try {
    if (newTrainData.value.trainId && newTrainData.value.originDepartureDate) {

      const submitTrainCall = await backendCall.post('trains', newTrainData.value);
      if (!submitTrainCall) {
        throw new Error('API Error')
      }
      newTrainData.trainId = null;
      newTrainData.eta = null;
      newTrainData.originDepartureDate = null;
      emit('visible-change');
      trainStore.fetchTrains()
      emit('fetch-trains')
    }
  } catch (e) {
    toast.add({ severity: 'error', summary: 'Could not add train!', detail: 'An error occured while adding the train.', life: 5000 });

  }
}

</script>

<template>
  <Dialog :visible="props.visible" @update:visible="$emit('visible-change')" appendTo="body" modal
          :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '42vw'}" header="Edit Payment Method"
          :draggable="false" :resizable="false">
    <template #header>
      <div class="flex flex-col gap-2">
        <h1 class="m-0 text-surface-900 dark:text-surface-0 font-semibold text-xl leading-normal">New Train</h1>
        <span class="text-surface-600 dark:text-surface-200 text-base">Create new train</span>
      </div>
    </template>
    <form class="flex flex-col gap-4 mt-4">
      <div>
        <label for="train-id" class="block mb-1 text-color text-base">Train ID</label>
        <span class="p-input-icon-left w-full">
<!--                <i class="pi pi-user"></i>-->
                <InputText v-model="newTrainData.trainId" :invalid="!newTrainData.trainId" name="train-id" type="text" class="w-full" id="train-id"/>
            </span>
      </div>
      <div class="flex gap-4">
        <div class="w-full">
          <label for="origin-departure-date" class="block mb-1 text-color text-base">Origin Departure Date</label>
          <Calendar id="origin-departure-date" v-model="newTrainData.originDepartureDate" :invalid="!newTrainData.originDepartureDate" dateFormat="mm/dd/yy" class="w-full"/>
        </div>
        <div class="w-full">
          <label for="eta" class="block mb-1 text-color text-base">ETA To Midpoint</label>
          <span class="p-input-icon-left w-full">
<!--                    <i class="pi pi-lock"></i>-->
            <Calendar show-time hour-format="24" id="eta" v-model="newTrainData.eta" dateFormat="mm/dd/yy" class="w-full"/>
          </span>
        </div>
      </div>
    </form>
    <template #footer>
      <div class="flex gap-4 justify-end border-t border-surface pt-8">
        <Button label="Cancel" @click="$emit('visible-change')" class="p-button-text"></Button>
        <Button label="Submit" @click="submitNewTrain" class="p-button-rounded"></Button>
      </div>
    </template>
  </Dialog>

</template>

<style scoped>

</style>