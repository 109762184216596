<script setup>
import {onMounted, ref} from "vue";
import NewRefuelDialog from "../../components/fuel/NewRefuelDialog.vue";
import RefuelInfoDialog from "../../components/fuel/RefuelInfoDialog.vue";
import backendCall from "../../services/AxiosService.js";
const newRefuelModalVisible = ref(false);
const refuelList = ref([]);

const refuelInfoDialogVisible = ref(false);
const selectedRefuel = ref({});

const openRefuelInfoDialog = (refuelData) => {
  selectedRefuel.value = refuelData;
  refuelInfoDialogVisible.value = true;
}



onMounted(async()=>{
  const refuelQuery = await backendCall.get('fuel');
  refuelList.value = refuelQuery.data.data.refuels;
})

</script>

<template>
  <div class="bg-surface-0 dark:bg-surface-900 p-6 shadow rounded-border">
    <div class="mb-4 flex items-center justify-between">
      <span class="text-xl font-medium text-surface-900 dark:text-surface-0">Refueling Log</span>
      <Button label="New" icon="pi pi-plus" @click="newRefuelModalVisible = true"></Button>
    </div>
    <div class="border-2 border-solid rounded-xl p-1 border-surface">
      <DataTable :value="refuelList">
        <Column field="refuel_id" header="ID"></Column>
        <Column field="equipment_id" header="Unit"></Column>
        <Column field="start_fl" header="Start Level"></Column>
        <Column field="end_fl" header="End Level"></Column>
        <Column field="fuel_added" header="Liters Added"></Column>
        <Column header="Actions">
          <template #body="slotProps">
            <Button label="View" outlined @click="openRefuelInfoDialog(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
      <NewRefuelDialog v-model:visible="newRefuelModalVisible"></NewRefuelDialog>
    </div>
  </div>

  <RefuelInfoDialog v-model:refuel-info-dialog-visible="refuelInfoDialogVisible" :refuelInfo="selectedRefuel"></RefuelInfoDialog>

</template>

<style scoped>

</style>