import {defineStore} from 'pinia';
import backendCall from "../services/AxiosService.js";


export const useTrainsStore = defineStore({
    id: 'trains', state: () => ({
        lineup: [],
        trainCount: null
    }), actions: {
         fetchTrains() {
            backendCall.get('trains/lineup')
                .then(response => {
                    const data = response.data;
                    if (Array.isArray(data.content)) {
                        const newTrains = data.content.map(train => ({
                            ...train,
                            origin_depart_date_string: new Date(train.train_origin_departure_date).toDateString(),
                            eta_string: train.eta ? new Date(train.eta).toLocaleString() : null,
                            location: '',
                        }));

                        this.lineup = newTrains;
                        this.trainCount = newTrains.length;



                    }
                })
                .catch(error => {
                    console.error('There was a problem with the axios operation:', error);
                });
        }
    },

});
