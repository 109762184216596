<script setup lang="ts">
import {onMounted, ref} from "vue";
import NewYardCheckUnitDialog from "../../components/yard-check/NewYardCheckUnitDialog.vue";
import backendCall from "../../services/AxiosService.js";
import { useConfirm } from "primevue/useconfirm";
import {capitalize} from "../../utilities/stringFunctions";
import EditYardCheckUnitDialog from "../../components/yard-check/EditYardCheckUnitDialog.vue";

const confirm = useConfirm();


const props = defineProps({yardCheckId: String});

const op = ref();
const alarmsPopup = ref();
const selectedUnitAlarmData = ref(null);
const additionalInfoElectrics = ref();
const additionalInfoDomestics = ref();
const selectedElectricAdditionalInfo = ref(null);
const selectedUnitAdditionalInfo = ref(null);


const selectedFuelData = ref(null);


const newUnitDialogVisible = ref(false);
const editUnitDialogVisible = ref(false);
const editYardCheckUnitData = ref(null);
const yardCheckUnits = ref({});

const fetchYardCheck = async () => {
  try {
    const fetchYardChecksCall = await backendCall.get(`yard-check/${props.yardCheckId}?sortmode=unsorted`);
    yardCheckUnits.value = fetchYardChecksCall.data.data;
  } catch (e) {
    console.error(e);
  }
}

const toggleFuelDetailsOverlay = (event) => {
  op.value.toggle(event);
}

const toggleAlarmsPopup = (event, data) => {
  selectedUnitAlarmData.value = data;
  alarmsPopup.value.toggle(event);
}

const showFuelDetails = (event, data) => {
  selectedFuelData.value = data;
  op.value.toggle(event);
};

const showEditUnitDialog = (event, data) => {
  editYardCheckUnitData.value = data;
  editUnitDialogVisible.value = true;
};

const showAdditionalInfoElectrics = (event, data) => {
  selectedElectricAdditionalInfo.value = data;
  additionalInfoElectrics.value.toggle(event);
};

const showAdditionalInfoDomestics = (event, data) => {
  selectedUnitAdditionalInfo.value = data;
  additionalInfoDomestics.value.toggle(event);
};

onMounted(async () => {
  await fetchYardCheck();
})

const fuelWarningStyle = (unit) => {
  if ((unit.start_fuel_level === '1/8' || unit.start_fuel_level === '1/4' || unit.start_fuel_level === '3/8' || unit.start_fuel_level === '7/16' || unit.start_fuel_level === '1/2' || unit.start_fuel_level === '5/8' || unit.start_fuel_level === '9/16' || unit.start_fuel_level === '3/4') && !unit.fuel_added && (unit.event_code === 'IG' || unit.event_code === 'AS' || !unit.event_code)) {
    // return { outline: '20px solid yellow;', color: 'black' };
    return {color: 'red'};
  }
};

const markYardCheckComplete = async () => {
  await backendCall.post(`yard-check/${props.yardCheckId}/complete`);
}

const confirmYardCheckComplete = () => {
  confirm.require({
    message: 'Are you sure you want to mark this yard check complete? This will prevent further modifications.',
    header: 'Yard Check Complete?',
    icon: 'pi pi-info-circle',
    rejectLabel: 'Cancel',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Confirm',
      severity: 'danger'
    },
    accept: () => {
      console.log('Yard Check Complete');
     markYardCheckComplete();
    },
    reject: () => {
      console.log('reject')
    }
  });
};

</script>

<template>
  <div class="flex flex-col bg-surface-0 dark:bg-surface-900 w-full shadow rounded-border p-4">
    <div class="mb-4 flex items-center justify-between w-full">
      <div></div>
      <span v-if="yardCheckUnits?.yardCheck" class="text-xl font-medium text-surface-900 dark:text-surface-0">{{ new Date(yardCheckUnits?.yardCheck?.date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        timeZone: 'UTC'
      })}} {{capitalize(yardCheckUnits?.yardCheck?.morning_afternoon)}} Yard Check</span>
      <div class="flex gap-4">
      <Button label="New Unit" icon="pi pi-plus" @click="newUnitDialogVisible = true"></Button>
        <Button label="Complete" icon="pi pi-check" severity="danger" @click="confirmYardCheckComplete()"></Button>
        <ConfirmDialog></ConfirmDialog>
      </div>
    </div>
    <div v-if="yardCheckUnits.units" class="flex flex-col md:flex-row w-full gap-4">
      <Card class="w-full">
        <template #header>
          <h3 class="mt-2 text-center font-bold">Checked Units</h3>
        </template>
        <template #content>
          <div class="border-2 rounded-xl text-sm font-bold border-solid border-surface p-1">
            <DataTable :value="yardCheckUnits.units" size="small" data-key="yard_check_id">
              <Column field="unit_number" header="Unit"></Column>
              <Column field="event_code" header="EV Code"></Column>
              <Column header="Billed SP">
                <template #body="slotProps">
                  {{
                    slotProps.data.billed_setpoint
                  }}{{ slotProps.data.billed_setpoint ? slotProps.data.billed_setpoint_unit : null }}
                </template>
              </Column>
              <Column header="Actual SP">
                <template #body="slotProps">
                  {{
                    slotProps.data.actual_setpoint
                  }}{{ slotProps.data.actual_setpoint ? slotProps.data.actual_setpoint_unit : null }}
                </template>
              </Column>
              <Column field="return_temp" header="Return">
                <template #body="slotProps">
                  {{ slotProps.data.return_temp }}{{
                    slotProps.data.return_temp ? slotProps.data.return_temp_unit : null
                  }}
                </template>
              </Column>
              <Column field="supply_temp" header="Supply">
                <template #body="slotProps">
                  {{
                    slotProps.data.supply_temp
                  }}{{ slotProps.data.supply_temp ? slotProps.data.supply_temp_unit : null }}
                </template>
              </Column>
              <Column header="Fuel Level">
                <template #body="slotProps">
                  <span :style="fuelWarningStyle(slotProps.data)">{{ slotProps.data.start_fuel_level }}</span>
                  <i v-if="slotProps.data.fuel_added" @click="showFuelDetails($event, slotProps.data)"
                     class="fa-solid fa-gas-pump ml-2" style="color: green"></i>

                  <Popover ref="op">
                    <template #default="{ data }">
                      <div class="flex flex-col gap-4 w-[25rem]">
                        <div v-if="selectedFuelData">
                          <div class="font-bold">Unit Refuelled</div>
                          <div>Start Fuel Level: {{ selectedFuelData.start_fuel_level }}</div>
                          <div> Fuel Added: {{ selectedFuelData.fuel_added }}</div>
                          <div> End Fuel Level: {{ selectedFuelData.end_fl }}</div>
                        </div>
                      </div>
                    </template>
                  </Popover>
                </template>
              </Column>
              <Column header="Alarms">
                <template #body="slotProps">
                  <Button v-if="slotProps.data.alarms.length > 0" @click="toggleAlarmsPopup($event, slotProps.data)" rounded icon="pi pi-exclamation-circle"
                          severity="warn"/>
                  <Popover v-if="slotProps.data.alarms.length > 0" ref="alarmsPopup">
                    <template #default="{ data }">
                      <div class="flex flex-col gap-4 w-[25rem]">
                        <div class="font-bold text-center">{{selectedUnitAlarmData.unit_number}} Alarm List</div>
                      <div v-for="alarm of selectedUnitAlarmData.alarms">{{alarm.alarm_code}} - {{alarm.alarm_name}}</div>
<!--                          <div class="font-bold">{{ slotProps.data.unit_number }} Alarm List</div>-->
<!--                          <div> Event Code: {{selectedDomesticAdditionalInfo.event_code}}</div>-->

                      </div>
                    </template>
                  </Popover>
                </template>
              </Column>
              <Column>
                <template #body="slotProps">
                  <Button icon="pi pi-search" @click="showAdditionalInfoDomestics($event, slotProps.data)"
                          severity="success" size="small" text rounded aria-label="Search"/>
                  <Button @click="showEditUnitDialog($event, slotProps.data)" size="small" severity="warn" outlined text icon="pi pi-pen-to-square"></Button>
                  <Popover ref="additionalInfoDomestics">
                    <template #default="{ data }">
                      <div class="flex flex-col gap-4 w-[25rem]">
                        <div v-if="selectedUnitAdditionalInfo">
                          <div class="font-bold">{{ selectedUnitAdditionalInfo.unit_number }}</div>
                          <div> Time: {{new Intl.DateTimeFormat('en-US', {hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false}).format(new Date(selectedUnitAdditionalInfo.time)) }}</div>
                          <div> Event Code: {{ selectedUnitAdditionalInfo.event_code }}</div>
                          <div>Billed Setpoint:
                            {{ selectedUnitAdditionalInfo.billed_setpoint }}{{ selectedUnitAdditionalInfo.billed_setpoint ? selectedUnitAdditionalInfo.billed_setpoint_unit : null }}</div>
                          <div>Actual Setpoint:
                            {{ selectedUnitAdditionalInfo.actual_setpoint }}{{ selectedUnitAdditionalInfo.actual_setpoint ? selectedUnitAdditionalInfo.actual_setpoint_unit : null }}</div>
                          <div>Return Temp:
                            {{ selectedUnitAdditionalInfo.return_temp }}{{ selectedUnitAdditionalInfo.return_temp ? selectedUnitAdditionalInfo.return_temp_unit : null }}</div>
                          <div>Supply Temp:
                            {{ selectedUnitAdditionalInfo.supply_temp }}{{ selectedUnitAdditionalInfo.supply_temp ? selectedUnitAdditionalInfo.supply_temp_unit : null }}</div>
                          <div>Start Fuel Level: {{ selectedUnitAdditionalInfo.start_fuel_level }}</div>
                          <div> Fuel Added: {{ selectedUnitAdditionalInfo.fuel_added }}{{
                              selectedUnitAdditionalInfo.fuel_added ? 'L' : null
                            }}</div>
                          <div> End Fuel Level: {{ selectedUnitAdditionalInfo.end_fl }}</div>
                          <div> Check ID: {{ selectedUnitAdditionalInfo.check_id }}</div>
                          <div> Unit Check ID: {{ selectedUnitAdditionalInfo.unit_check_id }}</div>
                        </div>
                      </div>
                    </template>
                  </Popover>
                </template>
              </Column>
            </DataTable>
          </div>

        </template>
      </Card>
    </div>
    <NewYardCheckUnitDialog @refresh-yardcheck="fetchYardCheck" v-if="newUnitDialogVisible" v-model:visible="newUnitDialogVisible"
                            :yard-check-id="props.yardCheckId"></NewYardCheckUnitDialog>
    <edit-yard-check-unit-dialog v-if="editUnitDialogVisible" @refresh-yardcheck="fetchYardCheck" v-model:visible="editUnitDialogVisible" :yard-check-id="props.yardCheckId" :unit-data="editYardCheckUnitData"/>

  </div>
</template>

<style scoped>

</style>