<script setup>
import {ref} from "vue";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import backendCall from "../../../services/AxiosService.js";
import {useToast} from "primevue/usetoast";

const toast = useToast();

const priority = ref(false);

const props = defineProps({
  addElectricsModalVisible: Boolean,
  trainid: String
})

const emits = defineEmits(["visible-change", 'closeOutcomeModal']);

const submitElectricPayload = ref({
  unitNumber: null,
  priority: null,
  setpoint: null,
  sequence: null,
  carNumber: null,
  clipNumber: null
})

const submitElectric = async () => {
  try {
  const submitElectricCall = await backendCall.post(`trains/${props.trainid}/units`)
  if (!submitElectricCall) {
    throw new Error('Could add Electric to Train')
  }
} catch (e) {
    toast.add({severity: 'error', summary: 'Error!', detail: 'Could not add electric unit to train.', life: 5000});
  }
}
</script>

<template>
  <Dialog :visible="props.addElectricsModalVisible" @update:visible="$emit('visible-change')" appendTo="body" modal
          :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '40vw'}" header="Submit Outcome"
          :draggable="false" :resizable="false">
    <template #header>
      <div class="flex flex-col gap-2">
        <h1 class="m-0 text-surface-900 dark:text-surface-0 font-semibold text-xl leading-normal">Add Electrics</h1>
        <!--        <span class="text-surface-600 dark:text-surface-200 text-base">Quis enim lobortis scelerisque fermentum dui faucibus in ornare quam.</span>-->
      </div>
    </template>
    <form class="flex flex-col gap-4 mt-4">
      <div class="flex gap-4">
        <div class="w-full">
          <label for="unit_number" class="block mb-1 text-color text-base">Unit Number</label>
          <InputGroup>
            <InputGroupAddon>
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 19H21M3 5H21M4 5V19M20 5V19M8 8.5V15.5M16 8.5V15.5M12 8.5V15.5" stroke="#000000"
                      stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </InputGroupAddon>
            <InputText v-model="submitElectricPayload.unitNumber" name="unit_number" type="text" class="w-full" id="unit_number"/>
          </InputGroup>
        </div>
        <div class="w-full">

          <!--          <label for="priority-button" class="block mb-8 text-color text-base">Priority</label>-->
          <ToggleButton :pt="{
        box: () => ({
            class: [priority ? 'bg-yellow-500' : null]})}" id="priority-button" class="w-full mt-4" v-model="submitElectricPayload.priority"
                        onLabel="Priority" offLabel="Non-Priority"/>
        </div>
      </div>

      <div class="flex gap-4">
        <div class="w-full">
          <label for="setpoint" class="block mb-1 text-color text-base">Setpoint</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="fa-solid fa-temperature-three-quarters"></i>
            </InputGroupAddon>
            <InputText v-model="submitElectricPayload.setpoint" name="setpoint" type="text" class="w-full" id="setpoint"/>
          </InputGroup>
        </div>
        <div class="w-full">
          <label for="sequence" class="block mb-1 text-color text-base">Sequence</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="material-icons">train</i>
            </InputGroupAddon>
            <InputText v-model="submitElectricPayload.sequence" name="sequence" type="text" class="w-full" id="sequence"/>
          </InputGroup>
        </div>

      </div>
      <div class="flex gap-4">
        <div class="w-full">
          <label for="car-number" class="block text-color text-base">Car Number</label>
          <InputGroup>
            <InputGroupAddon>
              <svg width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>
                train-car-container</title>
                <path
                    d="M1 6V17H2C2 18.11 2.9 19 4 19S6 18.11 6 17H18C18 18.11 18.9 19 20 19S22 18.11 22 17H23V6H1M21 15H19V9H17V15H15V9H13V15H11V9H9V15H7V9H5V15H3V8H21V15Z"/>
              </svg>
            </InputGroupAddon>
            <InputText v-model="submitElectricPayload.carNumber" name="car-number" type="text"  class="w-full" id="car-number"
                       placeholder="DTTX"/>
          </InputGroup>
        </div>
        <div class="w-full">
          <label for="clip-number" class="block mb-1 text-color text-base">Clip Number</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-bolt"></i>
            </InputGroupAddon>
            <InputText v-model="submitElectricPayload.clipNumber" name="clip-number" type="text" class="w-full" id="clip-number"
                       placeholder="CNRJ"/>
          </InputGroup>
        </div>
      </div>
    </form>
    <template #footer>
      <div class="flex gap-4 justify-end border-t border-surface pt-8">
        <Button label="Cancel" @click="$emit('visible-change')" class="p-button-text"></Button>
        <Button label="Add" @click="submitElectric" class="p-button-rounded"></Button>
      </div>
    </template>
  </Dialog>

</template>

<style scoped>

</style>