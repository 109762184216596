<script setup>
import {useRouter} from "vue-router";
import NotFoundPage from "./NotFoundPage.vue";

const router = useRouter();
</script>

<template>
  <div class="bg-surface-0 dark:bg-surface-950 px-6 py-20 md:px-12 lg:px-20">
    <div class="flex flex-col lg:flex-row justify-center items-center gap-16">
      <div class="text-center lg:text-right">
        <div class="mt-12 mb-4 font-bold text-6xl text-surface-900 dark:text-surface-0">Are you lost?</div>
        <p class="text-surface-700 dark:text-surface-100 text-3xl mt-0 mb-12">Sorry, we couldn't find the page.</p>
        <Button @click="router.push('/')" type="button" label="Go back to home page" class="p-button-outlined"></Button>
      </div>
      <div>
        <img src="/images/blocks/feedback/404-rocket.png" alt="Image" class="w-full md:w-[28rem]" />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>