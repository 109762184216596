<script setup>
import backendCall from "../../services/AxiosService.js";
import {onMounted, ref} from "vue";

const trainLineup = ref();

const fetchLineup = () => {
  backendCall.get('trains/lineup/detailed')
      .then(async response => {
        const data = response.data;
        if (Array.isArray(data.content)) {
          trainLineup.value = data.content.map(train => ({
            ...train,
            origin_depart_date_string: new Date(train.train_origin_departure_date).toDateString(),
            eta_string: train.eta ? new Date(train.eta).toLocaleString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hourCycle: 'h23'
            }) : null,
            location: '',
          }));

        } else {
          throw new Error('Data content is not an array');
        }
      })
      .catch(error => {
        console.error('There was a problem with the axios operation:', error);
      });
}

onMounted(() =>{
  fetchLineup()
})

</script>

<template>

  <div v-for="train in trainLineup" class="bg-surface-0 dark:bg-surface-950 px-6 py-2 md:px-12 lg:px-20" style="border: 1px solid; border-radius: 5px">
    <div class="font-medium text-xl p-1 mb-2 text-surface-900 dark:text-surface-0" ><strong>{{train.dashed_train_id}}</strong></div>
    <div class="font-medium text-lg p-1 mb-2 text-surface-900 dark:text-surface-0">
      ETA: {{new Date(train.eta).toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hourCycle: 'h23'
    })}}
    </div>
    <div class="leading-normal text-lg text-surface-700 dark:text-surface-100 mb-1 ml-6">
      Gensets: ({{train.gensetCount}}) <span v-for="(genset, index) of train.gensets">{{genset.genset_number}}<span v-if=" index !== train.gensets.length - 1 ">, </span></span>
    </div>
    <div class="leading-normal text-lg text-surface-700 dark:text-surface-100 mb-1 ml-6">
    Electrics: ({{train.electricCount}})
      <div v-if="train.electric_notes">Notes: {{train.electric_notes}}</div>
    </div>
    <div class="leading-normal text-lg text-surface-700 dark:text-surface-100 mb-1 ml-6">
      Domestics: ({{train.dieselCount}})
      <div v-if="train.diesel_notes">Notes: {{train.diesel_notes}}</div>
    </div>
    <div class="leading-normal text-lg text-surface-700 dark:text-surface-100 mb-1 ml-6">
      Requests: ({{train.requestCount}})
    </div>
      <div class="mb-2 ml-20" v-for="request of train.requests" :key="request.id">
        {{request.equipment_id}}: Setpoint: {{request.request_billed_setpoint}}{{request.request_billed_setpoint_temp_unit}} &emsp; {{request.request_notes}}
    </div>
    <br>
    <br>
  </div>
</template>

<style scoped>

</style>