import { ref, watch } from 'vue'

export function createThemeHandler(authStore) {
    const isDarkMode = ref(localStorage.getItem('theme') === 'dark')

    function applyTheme(dark) {
        const element = document.querySelector('html')
        if (dark) {
            element.classList.add('dark')
        } else {
            element.classList.remove('dark')
        }
        localStorage.setItem('theme', dark ? 'dark' : 'light')

        if (authStore) {
            authStore.darkMode = dark
        }
    }

    watch(isDarkMode, (newValue) => {
        applyTheme(newValue)
    }, { immediate: true })

    return { isDarkMode }
}