<template>
  <TrainOutcomeModal :trainid="props.trainid" :submit-outcome-modal-visible="trainOutcomeModalVisible"
                     @visibleChange="trainOutcomeModalVisible = false"
                     @closeOutcomeModal="trainOutcomeModalVisible = false"
                     @train-outcome-submit="postTrainOutcome"></TrainOutcomeModal>

  <AddElectricsDialog :trainid="props.trainid" :add-electrics-modal-visible="addElectricsDialogVisible"
                      @visibleChange="addElectricsDialogVisible = false"
                      @closeAddElectricsModal="addElectricsDialogVisible = false">

  </AddElectricsDialog>

  <AddDieselsDialog :trainid="props.trainid" :add-diesels-modal-visible="addDieselsModalVisible"
                    @visibleChange="addDieselsModalVisible = false"
                    @closeAddElectricsModal="addElectricsDialogVisible = false">
  </AddDieselsDialog>
  <SubscribeToUnitDialog v-model="subscribeToUnitDialogVisible" :subscribe-unit="subscribeUnit"
                         @clear-subscribe-unit=" subscribeUnit.value = null "/>

  <div class="flex flex-col justify-between">
    <div class="bg-surface-0 dark:bg-surface-950 px-6 py-8 md:px-12 lg:px-20">
      <div class="flex flex-col md:items-center md:justify-between md:flex-row">
        <div></div>
        <div class="font-medium text-3xl text-surface-900 dark:text-surface-0">{{trainId}}</div>
        <div>
          <RouterLink :to="`/trains/${props.trainid}/fieldui`">
            <Button severity="success" label="Inspect Train"></Button>
          </RouterLink>
        </div>
<!--        <div class="mt-4 md:mt-0">-->
<!--          <Button label="Add" class="p-button-outlined mr-2" icon="pi pi-user-plus"></Button>-->
<!--          <Button label="Save" icon="pi pi-check"></Button>-->
<!--        </div>-->
      </div>
    </div>
    <div class="mr-6 flex flex-col flex-1 xl:flex-row justify-between">
      <div class="flex-none">
        <router-link to="/trains">
          <Button class="m-2" severity="info" outlined>Lineup</Button>
        </router-link>
        <Button class="m-2" severity="danger" outlined @click="trainOutcomeModalVisible = true">Submit Outcome
        </Button>
      </div>

      <div class="flex-none">
        <Button @click="addElectricsDialogVisible = true" class="m-2" severity="success" outlined>Add Electrics</Button>
        <router-link :to="`/trains/${trainid}/bulkelectrics`">
          <Button class="m-2" severity="success" outlined>Add Electrics (bulk)</Button>
        </router-link>
        <Button @click="addDieselsModalVisible = true" class="m-2 mr-0" severity="info" outlined>Add Diesels</Button>

          <Button @click="$refs.menu.toggle($event)" class="m-2 border-0" rounded text>
            <i class="pi pi-ellipsis-v"></i>
          </Button>
          <Menu ref="menu" class="mt-2" appendTo="body" popup :model="ellipsisButtonItems"></Menu>

      </div>

    </div>

    <div class="my-6">
      <ul class="bg-surface-0 dark:bg-surface-900 p-0 m-0 list-none flex overflow-x-auto select-none">
        <li class="flex-1 flex justify-center">
          <a v-ripple
             class="cursor-pointer px-6 py-4 flex items-center justify-center border-b-2 hover:border-surface-500 dark:hover:border-surface-300 transition-colors duration-150 p-ripple"
             :class="{'border-primary-500 text-primary-500 hover:border-primary-500': activeTab === 0, 'text-700 border-transparent': activeTab !== 0}"
             @click="activeTab = 0">
            <Badge class="mr-2" :value="totalUnits" />

            <span class="font-medium">All</span>
          </a>
        </li>
        <li class="flex-1 flex justify-center">
          <a v-ripple
             class="cursor-pointer px-6 py-4 flex items-center justify-center border-b-2 hover:border-surface-500 dark:hover:border-surface-300 transition-colors duration-150 p-ripple"
             :class="{'border-primary-500 text-primary-500 hover:border-primary-500': activeTab === 1, 'text-700 border-transparent': activeTab !== 1}"
             @click="activeTab = 1">
            <Badge class="mr-2" :value="electrics.length" />

            <span class="font-medium">Electrics</span>
          </a>
        </li>
        <li class="flex-1 flex justify-center">
          <a v-ripple
             class="cursor-pointer px-6 py-4 flex items-center justify-center border-b-2 hover:border-surface-500 dark:hover:border-surface-300 transition-colors duration-150 p-ripple"
             :class="{'border-primary-500 text-primary-500 hover:border-primary-500': activeTab === 2, 'text-700 border-transparent': activeTab !== 2}"
             @click="activeTab = 2">
<!--            <span-->
<!--                class="inline-flex items-center justify-center ml-auto bg-primary-500 text-surface-0 dark:text-surface-900 rounded-full mr-2"-->
<!--                style="min-width: 1.5rem; height: 1.5rem">{{ requests.length }}</span>-->
            <Badge class="mr-2" :value="requests.length" />
            <span class="font-medium">Requests</span>
          </a>
        </li>
        <li class="flex-1 flex justify-center">
          <a v-ripple
             class="cursor-pointer px-6 py-4 flex items-center justify-center border-b-2 hover:border-surface-500 dark:hover:border-surface-300 transition-colors duration-150 p-ripple"
             :class="{'border-primary-500 text-primary-500 hover:border-primary-500': activeTab === 3, 'text-700 border-transparent': activeTab !== 3}"
             @click="activeTab = 3">
            <Badge class="mr-2" :value="domestics.length" />

            <span class="font-medium">Domestics</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div v-if="activeTab === 0 || activeTab === 2" class="grid grid-cols-12 gap-4 m-6">
    <div class="col-span-12">
      <RequestList header-title="Requests" :units="requests" @open-subscribe-dialog="openUnitSubscribeDialog">
      </RequestList>

    </div>
  </div>
<div class="grid grid-cols-12 gap-4 mx-1">
  <div v-if="activeTab === 0" class="col-span-12 xl:col-span-6">
    <div>
      <ElectricList header-title="Electrics" :units="electrics" detail-level="summary" @open-subscribe-dialog="openUnitSubscribeDialog">
      </ElectricList>
    </div>
  </div>

  <div v-if="activeTab === 1" class="col-span-12 flex justify-center">
    <div style="width: 75%" class="">
      <ElectricList header-title="Electrics" :units="electrics" detail-level="detailed" @open-subscribe-dialog="openUnitSubscribeDialog">
      </ElectricList>
    </div>
  </div>

    <div v-if="activeTab === 0" class="col-span-12 xl:col-span-6">
    <div>
      <DomesticList header-title="Diesels" :units="domestics" detail-level="summary" @open-subscribe-dialog="openUnitSubscribeDialog">
        <unitlist/>
      </DomesticList>
    </div>
    </div>

  <div v-if="activeTab === 3" class="col-span-12">
    <div>
      <DomesticList header-title="Diesels" :units="domestics" detail-level="detailed" @open-subscribe-dialog="openUnitSubscribeDialog">
        <unitlist/>
      </DomesticList>
    </div>
  </div>

</div>

<!--  Time Sidebar-->

  <div id="slideover-8" class="hidden bg-white dark:bg-black z-50 bg-surface-0 h-full absolute top-0 right-0 shadow w-72">
    <div class="flex flex-col h-full">
      <div class="flex items-center justify-between mb-6 p-6">
        <span class="text-surface-900 dark:text-surface-0 text-xl font-medium">Time Tracking</span>
        <Button icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain"
                v-styleclass="{ selector: '#slideover-8', enterFromClass: 'hidden', enterActiveClass: 'my-fadein', leaveActiveClass: 'my-fadeout', leaveToClass: 'hidden', hideOnOutsideClick: true }"></Button>

      </div>
      <div class="flex-auto overflow-y-auto">
        <ul class="list-none p-0 m-0">
          <li class="flex items-center border-t border-surface p-4">
                         <span v-if="timeStarted" class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent" style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span v-else class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent" style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span class="text-surface-700 dark:text-surface-100 font-medium">
            <Button @click="toggleBeginTime" label="Begin Time" text outlined></Button>
            </span>
          </li>
          <li class="flex items-center border-t border-surface p-4">
                          <span v-if="waitTimeStarted" class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent" style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span v-else class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent" style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span class="text-surface-700 dark:text-surface-100 font-medium">
              <Button @click="toggleWaitTime" label="Start Wait Time" text outlined></Button>
            </span>
          </li>
          <li class="flex items-center border-t border-surface p-4">
                    <span v-if="trainArrived" class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent" style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span v-else class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent" style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span class="text-surface-700 dark:text-surface-100 font-medium">
              <Button @click="toggleTrainArrived" label="Train Arrived" text outlined></Button>
            </span>
          </li>
          <li class="flex flex-col justify-evenly border-t border-surface p-4">
            <div>
              <InputText id="track-input" v-model="protectionTrack" :disabled="protectionStarted" placeholder="Track"></InputText>
            </div>
            <div class="mt-2">
                    <span v-if="protectionStarted" class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent" style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span v-else class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent" style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span class="text-surface-700 dark:text-surface-100 font-medium">
              <Button @click="startProtection" :disabled="!protectionTrack" label="Protection Start" text outlined></Button>
            </span>
            </div>
          </li>
          <li class="flex items-center border-t border-surface p-4">
                    <span v-if="protectionEnded" class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent" style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span v-else class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent" style="width:35px;height:35px">
                        <i class="pi pi-check"></i>
                    </span>
            <span class="text-surface-700 dark:text-surface-100 font-medium">
              <Button @click="endProtection" label="Protection End" text outlined></Button>
            </span>
          </li>
          <li class="flex items-center border-t border-surface p-4">
                    <span v-if="trainComplete" class="rounded-full bg-green-500 text-white text-2xl inline-flex items-center justify-center mr-4 border border-transparent" style="width:35px;height:35px">
                        <i class="pi pi-thumbs-up"></i>
                    </span>
            <span v-else class="rounded-full border-primary bg-white text-black text-2xl inline-flex items-center justify-center mr-4 border border-transparent" style="width:35px;height:35px">
                        <i class="pi pi-thumbs-up"></i>
                    </span>
            <span class="text-surface-700 dark:text-surface-100 font-medium">
              <Button @click="trainComplete = true" label="Train Complete" text outlined></Button>
            </span>
          </li>

<!--          <li class="flex items-center border-t border-surface p-4">-->
<!--                    <span class="rounded-full text-2xl inline-flex items-center justify-center mr-4 border border-surface" style="width:35px;height:35px">-->
<!--                        <i class="pi pi-thumbs-up text-surface-700 dark:text-surface-100"></i>-->
<!--                    </span>-->
<!--            <span class="text-surface-700 dark:text-surface-100 font-medium">Confirmation</span>-->
<!--          </li>-->
        </ul>
      </div>
    </div>
  </div>
  <Button class="!fixed" :pt="{ root: 'time-button' }" v-styleclass="{ selector: '#slideover-8', enterFromClass: 'hidden', enterActiveClass: 'my-fadein', leaveActiveClass: 'my-fadeout', leaveToClass: 'hidden', hideOnOutsideClick: true }">

    <i class="pi pi-clock"></i>
  </Button>
</template>

<script setup>
import Unitlist from "../../components/trains/consist/DomesticList.vue";
import {computed, onMounted, ref} from "vue";
import TrainOutcomeModal from "../../components/trains/consist/TrainOutcomeModal.vue";
import {useAuthStore} from "../../stores/authStore.js";
import DomesticList from "../../components/trains/consist/DomesticList.vue";
import ElectricList from "../../components/trains/consist/ElectricList.vue";
import RequestList from "../../components/trains/consist/RequestList.vue";
import backendCall from "../../services/AxiosService.js";
import AddElectricsDialog from "../../components/trains/consist/AddElectricsDialog.vue";
import AddDieselsDialog from "../../components/trains/consist/AddDieselsDialog.vue";
import {useRoute} from "vue-router";
import {useToast} from "primevue/usetoast";
import {useTrainsStore} from "../../stores/trainsStore.js";
import SubscribeToUnitDialog from "../../components/trains/consist/SubscribeToUnitDialog.vue";
import router from "../../router/index.js";
// import Breadcrumb from "@/components/Breadcrumb.vue";

const props = defineProps(['trainid'])
const authStore = useAuthStore();
const trainsStore = useTrainsStore()
const electrics = ref([]);
const domestics = ref([]);
const requests = ref([]);
const route = useRoute();
const toast = useToast();
const trainOutcomeModalVisible = ref(false);
const addElectricsDialogVisible = ref(false);
const addDieselsModalVisible = ref(false);
const activeTab = ref(0);
const subscribeUnit = ref(null);
const trainArrived = ref(false);
const waitTimeStarted = ref(false);
const timeStarted = ref(false);
const protectionStarted = ref(false);
const protectionEnded = ref(false);
const protectionTrack=ref(null);
const trainComplete = ref(false);
const trainId = ref(null);
const submitOutcomeButton = () => {
  trainOutcomeModalVisible.value = true;
}

const totalUnits = computed(() => {
  return electrics.value.length + requests.value.length + domestics.value.length
})

const toggleTrainArrived = () => {
  trainArrived.value = !trainArrived.value;
}

const toggleBeginTime = () => {
  timeStarted.value = !timeStarted.value;
}

const toggleWaitTime = () => {
  waitTimeStarted.value = !waitTimeStarted.value;
}

const endProtection = () => {
  protectionStarted.value = false;
  protectionEnded.value = true;
  protectionTrack.value = null;
}

const startProtection = () => {
  protectionStarted.value = true;
  protectionEnded.value = false;
}

const subscribeToUnitDialogVisible = ref(false);

const openUnitSubscribeDialog = (unit) => {
  subscribeUnit.value = unit;
  subscribeToUnitDialogVisible.value = true;
}

const ellipsisButtonItems = ref([{
  label: 'View Time',
  icon: 'pi pi-clock',
  command: () => {
    router.push( `/trains/${props.trainid}/time`);
  }
},{
  label: 'Delete Train',
  icon: 'pi pi-trash',
  command: () => {
    toast.add({severity: 'warn', summary: 'Search Completed', detail: 'No results found', life: 3000});
  }
}
]);

const addElectricsButton = () => {
  addElectricsDialogVisible.value = true;
}

const getTrainUnits = async () => {

  await backendCall.get(`${import.meta.env.VITE_API_BASE_URL}trains/${props.trainid}/units`)
      .then(response => {

        return response;
      })
      .then(data => {
        trainId.value = data?.data.data.train_id_formatted;
        electrics.value = data?.data.data.electrics;
        domestics.value = data?.data.data.domestics;
        requests.value = data?.data.data.requests;
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });

}

const postTrainOutcome = async (resultObject) => {
  try {
    const postResult = await backendCall.post(`trains/${route.params.trainid}/outcome`, resultObject);
    if (!postResult) {
      throw new Error('Could Not Submit Train Result')
    }
    trainsStore.fetchTrains()
    trainOutcomeModalVisible.value = false
  } catch (e) {
    toast.add({
      severity: 'error',
      summary: 'Could submit train outcome!',
      detail: 'An error occured while submitting the train outcome.',
      life: 5000
    });

  }
}

onMounted(() => {
  getTrainUnits()
});

</script>

<style scoped>
.time-button {
  display: block;
  position: fixed !important;
  width: 52px;
  height: 52px;
  line-height: 52px;
  background-color: var(--primary-color);
  text-align: center;
  align-content: center !important;
  color: var(--primary-color-text);
  top: 50%;
  right: 0;
  z-index: 100;
  overflow: hidden;
  cursor: pointer;
  outline: 0 none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

@keyframes my-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes my-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.my-hidden {
  display: none;
}

.my-fadein {
  animation: my-fadein 150ms linear;
}

.my-fadeout {
  animation: my-fadeout 150ms linear;
}
</style>