import { defineStore } from 'pinia';
import backendCall from "../services/AxiosService.js";
import axios from "axios";
import router from "../router/index.js";

export const useAuthStore = defineStore({
    id: 'user',
    state: () => ({
        token: localStorage.getItem('authToken') || null,
        user: null,
        darkMode: false,
    }),
    actions: {
        setToken(newToken) {
            this.token = newToken;
            // backendCall.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
            localStorage.setItem('authToken', newToken);
        }, setUser(user) {
            this.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        async fetchToken(credentials) {
            try {
                const response = await axios.post(import.meta.env.VITE_API_BASE_URL + 'auth/login', credentials, {withCredentials: true})
                // const response = await fetch('https://rms.janssenequipment.com/api/authenticate', {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json'
                //     },
                //     body: JSON.stringify(credentials)
                // });
                // if (!response.ok) {
                //     throw new Error('Network response was not ok');
                // }
                //
                // const data = await response.json();
                // console.log(data)
//todo finish setting up axios. Calls for data still need to be switched to axios as well so that we can use an interceptor to void the token when a 401 is returned from the api.

                // this.setToken(response.data.token);
                if (response.data.user) {
                    this.setUser(response.data.user);
                } else {
                   await this.logout()
                }
            } catch (error) {
                console.error('There was a problem fetching the token:', error);
                return('Invalid Username or Password!');
            }
        },
       async logout(){
            try {
                await backendCall.get('/auth/logout');

                this.token = null;
                this.user = null;
                localStorage.removeItem('authToken');
                localStorage.removeItem('user');
                await router.push('/login');
            } catch (e) {

            }
        }
    }
});
