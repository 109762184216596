<template>
  <div class="mb-8 flex">
    <div v-if=" pretripPass === null" class="flex flex-col items-center" style="width: 2rem;"><span
        class="bg-blue-500 text-surface-0 dark:text-surface-900 flex items-center justify-center rounded-full p-2"><i
        class="pi pi-wrench text-xl"></i></span>
      <div class="h-full bg-blue-500" style="width: 2px; min-height: 4rem;"></div>
    </div>
    <div v-else-if="pretripPass === 'true'" class="flex flex-col items-center" style="width: 2rem;"><span
        class="bg-green-500 text-surface-0 dark:text-surface-900 flex items-center justify-center rounded-full p-2"><i
        class="pi pi-wrench text-xl"></i></span>
      <div class="h-full bg-green-500" style="width: 2px; min-height: 4rem;"></div>
    </div>
    <div v-else class="flex flex-col items-center" style="width: 2rem;"><span
        class="bg-red-500 text-surface-0 dark:text-surface-900 flex items-center justify-center rounded-full p-2"><i
        class="pi pi-wrench text-xl"></i></span>
      <div class="h-full bg-red-500" style="width: 2px; min-height: 4rem;"></div>
    </div>
    <div class="ml-8 bg-surface-0 dark:bg-surface-900 shadow rounded-border p-4 flex-auto">
      <div class="mb-4"><span class="text-surface-900 dark:text-surface-0 font-semibold inline-block mr-4">{{employeeFirstName}} {{employeeLastName}}</span><span
          class="text-surface-500 dark:text-surface-300 text-sm">{{eventTimeString}}</span></div>
      <div v-if="clipEvent === 'removed' || clipEvent ==='installed'" class="leading-normal text-surface-700 dark:text-surface-100"> {{capitalize(clipEvent)}} {{clipNumber}} {{ontoFrom}} {{containerNumber}}.
      </div>
      <div v-else-if="clipEvent === 'pretripped'" class="leading-normal font-medium text-surface-700 dark:text-surface-100 mb-4"> {{clipNumber}} {{pretripPass === 'true' ? 'Passed' : 'Failed'}} Pretrip.
      </div>
      <div class="leading-normal text-surface-500 dark:text-surface-300 mb-4">
        {{notes}}
      </div>
<!--      <div class="text-surface-500 dark:text-surface-300 flex items-center gap-6">-->
<!--        <div class="flex items-center gap-1"><i class="pi pi-heart"></i><span>0</span></div>-->
<!--        <div class="flex items-center gap-1"><i class="pi pi-comment"></i><span>1</span></div>-->
<!--        <div class="flex items-center gap-1"><i class="pi pi-eye"></i><span>24</span></div>-->
<!--      </div>-->
    </div>
  </div>
</template>
<script setup>
import {ref} from 'vue';
const props = defineProps({employeeFirstName: String, employeeLastName: String, clipEvent: String, clipNumber: String, containerNumber: String, setpoint: String, return: String, supply: String, notes: String, eventTime: String, pretripPass: String || null});
const ontoFrom = ref();
if (props.clipEvent === 'installed') {
  ontoFrom.value = 'on to'
} else if (props.clipEvent === 'removed') {
  ontoFrom.value = 'from'
} else {
  ontoFrom.value = null
}

const eventTimeString = new Date (props.eventTime).toLocaleString('en-US', {
  hour: '2-digit',
  minute: '2-digit',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hourCycle: 'h23'
});

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
</script>