<script setup>
import {ref} from 'vue';
import {useAuthStore} from "../../stores/authStore.js";
import router from "../../router/index.js";
import {useToast} from 'primevue/usetoast';
import Footer from "../../components/Footer.vue";


const authStore = useAuthStore();
const credentials = ref({
  username: '',
  password: ''
});

const toast = useToast();

const checked = ref(false);

const login = async () => {
  try {
    const loginResult = await authStore.fetchToken(credentials.value);
    if (loginResult === 'Invalid Username or Password!') {
      toast.add({severity: 'error', summary: 'Error', detail: loginResult, life: 3000});
    }
    await router.push('/trains');
  } catch (e) {

  }
};


</script>

<template>

  <div class="h-screen flex flex-col flex-wrap gap-4 items-center justify-center">
    <div class="bg-surface-0 dark:bg-surface-900 p-6 shadow-md rounded-xl w-full lg:w-4/12 items-center">
      <div class="text-center mb-8">
        <img src="/logo.png" alt="Image" height="50" class="mx-auto mb-4"/>
        <div class="text-surface-900 dark:text-surface-0 text-3xl font-medium mb-4">Welcome Back</div>
      </div>

      <form @submit.prevent="login">
        <label for="email1" class="block text-surface-900 dark:text-surface-0 font-medium mb-2">Username</label>
        <InputText v-model="credentials.username" id="email1" type="text" placeholder="Username" class="w-full mb-4"/>
<!--        <Message v-if="!credentials.username" severity="error">Username Required!</Message>-->

        <label for="password1" class="block text-surface-900 dark:text-surface-0 font-medium mb-2">Password</label>
        <InputText v-model="credentials.password" id="password1" type="password" placeholder="Password" class="w-full mb-4"/>
<!--        <Message v-if="!credentials.password" severity="error">Password Required!</Message>-->


        <div class="flex items-center justify-between mb-12">
          <div class="flex items-center">
            <Checkbox id="rememberme" :binary="true" v-model="checked" class="mr-2"></Checkbox>
            <label for="rememberme">Remember me</label>
          </div>
          <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot password?</a>
        </div>
        <Button type="submit" label="Sign In" icon="pi pi-user" class="w-full"></Button>
      </form>
    </div>
    <Footer class=" bottom-0"/>
  </div>

</template>

<style scoped>

</style>