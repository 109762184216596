<script setup>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

const calendarOptions= {
    plugins: [ dayGridPlugin, interactionPlugin ],
    initialView: 'dayGridMonth'
  }

</script>

<template>
  <FullCalendar :options="calendarOptions" />
</template>

<style scoped>

</style>