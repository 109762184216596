<script setup lang="ts">
import {ref} from "vue";
import backendCall from "../../services/AxiosService.js";
import {useToast} from "primevue/usetoast";

const toast = useToast();

const props = defineProps({visible: Boolean});
const emits = defineEmits(['visible-change'])

interface NewUser {
  firstName: string | null,
  lastName: string | null,
  username: string | null,
  email: string | null,
  password: string| null,
  confirmPassword: string | null,
  phone: string | null
}

const newUser = ref<NewUser>({
  firstName: null,
  lastName: null,
  username: null,
  email: null,
  password: null,
  confirmPassword: null,
  phone: null
});
const roles = ref([]);
const rolesList = ref([]);

const submitNewUser = async () => {
  try {
    const submitUserCall = await backendCall.post('users', newUser);
    if (!submitUserCall) {
      toast.add({severity: 'error', summary: 'Error!', detail: 'Could not add new user!', life: 3000});
    }
  } catch (e) {
    toast.add({ severity: 'error', summary: 'Error!', detail: 'Could not add new user!', life: 3000 });
  }
}

const allRoles = ['Admin', 'Dispatch', 'Billing', 'Field Tech'];
const roleSuggestions = ref([...allRoles]);

const search = (event) => {
  setTimeout(() => {
    const query = event.query.trim().toLowerCase();

    if (query.length === 0) {
      roleSuggestions.value = [...allRoles];
    } else {
      roleSuggestions.value = allRoles.filter((role) => {
        return role.toLowerCase().startsWith(query);
      });
    }
  }, 100);
};
</script>

<template>
  <Dialog :visible="props.visible" @update:visible="$emit('visible-change')" appendTo="body" modal
          :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '52vw'}" :draggable="false" :resizable="false">
    <template #header>
      <div class="flex flex-col gap-2">
        <h1 class="m-0 text-surface-900 dark:text-surface-0 font-semibold text-xl leading-normal">New User</h1>
        <span class="text-surface-600 dark:text-surface-200 text-base">Create a new user</span>
      </div>
    </template>


    <form>
      <div class="grid grid-cols-12 gap-4 formgrid p-fluid">
        <div class="field mb-6 col-span-6">
          <label for="first-name" class="font-medium text-surface-900 dark:text-surface-0">First Name</label>
          <InputText v-model="newUser.firstName" id="first-name" type="text"/>
        </div>
        <div class="field mb-6 col-span-6">
          <label for="last-name" class="font-medium text-surface-900 dark:text-surface-0">Last Name</label>
          <InputText v-model="newUser.lastName" id="last-name" type="text"/>
        </div>
        <div class="border-surface border-t opacity-50 mb-4 col-span-12"></div>
        <div class="field mb-6 col-span-12 md:col-span-6">
          <label for="username" class="font-medium text-surface-900 dark:text-surface-0">Username</label>
          <InputText v-model="newUser.username" id="username" type="text"/>
        </div>
        <div class="field mb-6 col-span-12 md:col-span-6">
          <label for="email" class="font-medium text-surface-900 dark:text-surface-0">Email</label>
          <InputText v-model="newUser.email" id="email" type="text"/>
        </div>
        <div class="field mb-6 col-span-12 md:col-span-6">
          <label for="phone" class="font-medium text-surface-900 dark:text-surface-0">Phone</label>
          <InputText v-model="newUser.phone" id="phone" type="text"/>
        </div>
        <div class="field mb-6 col-span-12 md:col-span-6">
          <label for="roles" class="font-medium text-surface-900 dark:text-surface-0">Roles</label>
          <div class="card p-fluid">
            <AutoComplete id="roles" v-model="roles" multiple :suggestions="roleSuggestions" @complete="search" />
          </div>
        </div>
        <div class="border-surface border-t opacity-50 mb-4 col-span-12"></div>

        <div class="field mb-6 col-span-6">
          <label for="password" class="font-medium text-surface-900 dark:text-surface-0">Password</label>
          <InputText v-model="newUser.password" :invalid="!newUser.password" id="password" type="password"/>
        </div>
        <div class="field mb-6 col-span-6">
          <label for="password-confirm" class="font-medium text-surface-900 dark:text-surface-0">Confirm</label>
          <InputText v-model="newUser.confirmPassword" :invalid="!newUser.confirmPassword || newUser.password !== newUser.password" id="password-confirm" type="password"/>
        </div>

        <!--          <div class="border-surface border-t opacity-50 mb-4 col-span-12"></div>-->
        <!--          <div class="field mb-6 col-span-12">-->
        <!--            <label for="privacy2" class="font-medium text-surface-900 dark:text-surface-0">Privacy</label>-->
        <!--            <div class="flex items-center">-->
        <!--              <InputSwitch id="privacy2" v-model="checked2"></InputSwitch>-->
        <!--              <span class="ml-2">Share my data with contacts</span>-->
        <!--            </div>-->
        <!--          </div>-->
      </div>
    </form>
      <template #footer>
        <div class="flex gap-4 justify-end border-t border-surface pt-8">
          <Button label="Cancel" @click="$emit('visible-change')" class="p-button-text"></Button>
          <Button label="Create" @click="submitNewUser" class="p-button-rounded"></Button>
        </div>

      </template>

  </Dialog>

</template>

<style scoped>

</style>