<script setup>

</script>

<template>
  <div class="bg-surface-50 dark:bg-surface-950 px-6 py-20 md:px-12 lg:px-20">
    <div class="text-surface-900 dark:text-surface-0 font-medium text-xl mb-4">Profile</div>
    <p class="m-0 mb-6 p-0 text-surface-600 dark:text-surface-200 leading-normal mr-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus deleniti expedita impedit iure, labore nulla obcaecati porro similique! Ab blanditiis consequuntur doloremque dolores eius esse facilis nihil pariatur rerum, sapiente.</p>
    <div class="bg-surface-0 dark:bg-surface-900 p-6 shadow rounded-border">
      <div class="grid grid-cols-12 gap-4 formgrid p-fluid">
        <div class="field mb-6 col-span-12">
          <label for="nickname2" class="font-medium text-surface-900 dark:text-surface-0">Nickname</label>
          <InputText id="nickname2" type="text" />
        </div>
        <div class="border-surface border-t opacity-50 mb-4 col-span-12"></div>
        <div class="field mb-6 col-span-12 md:col-span-6">
          <label for="bio2" class="font-medium text-surface-900 dark:text-surface-0">Bio</label>
          <Textarea id="bio2" type="text" :rows="5" :autoResize="true"></Textarea>
        </div>
        <div class="field mb-6 col-span-12 md:col-span-6">
          <label for="avatar2" class="font-medium text-surface-900 dark:text-surface-0">Avatar</label>
          <div class="flex items-center">
            <img src="/images/blocks/avatars/circle/avatar-f-4.png" class="mr-6" />
            <FileUpload id="avatar2" mode="basic" name="avatar" url="./upload.php" accept="image/*" :maxFileSize="1000000" class="p-button-outlined p-button-plain" chooseLabel="Upload Image"></FileUpload>
          </div>
        </div>
        <div class="border-surface border-t opacity-50 mb-4 col-span-12"></div>
        <div class="field mb-6 col-span-12 md:col-span-6">
          <label for="email2" class="font-medium text-surface-900 dark:text-surface-0">Email</label>
          <InputText id="email2" type="text" />
        </div>
        <div class="field mb-6 col-span-12 md:col-span-6">
          <label for="country2" class="font-medium text-surface-900 dark:text-surface-0">Country</label>
          <Dropdown id="country2" v-model="selectedCountry2" :options="countries" optionLabel="name" :filter="true" filterBy="name" :showClear="true" placeholder="Select a Country">
            <template #option="slotProps">
              <div class="flex items-center">
                <img src="/images/blocks/flag/flag_placeholder.png" :class="'mr-2 flag flag-' + slotProps.option.code.toLowerCase()" style="width:18px"/>
                <div>{{slotProps.option.name}}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="field mb-6 col-span-12 md:col-span-6">
          <label for="city2" class="font-medium text-surface-900 dark:text-surface-0">City</label>
          <InputText id="city2" type="text" />
        </div>
        <div class="field mb-6 col-span-12 md:col-span-6">
          <label for="state2" class="font-medium text-surface-900 dark:text-surface-0">State</label>
          <InputText id="state2" type="text" />
        </div>
        <div class="border-surface border-t opacity-50 mb-4 col-span-12"></div>
        <div class="field mb-6 col-span-12">
          <label for="website2" class="font-medium text-surface-900 dark:text-surface-0">Website</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">www</span>
            <InputText id="website2" type="text" />
          </div>
        </div>
        <div class="border-surface border-t opacity-50 mb-4 col-span-12"></div>
        <div class="field mb-6 col-span-12">
          <label for="privacy2" class="font-medium text-surface-900 dark:text-surface-0">Privacy</label>
          <div class="flex items-center">
            <ToggleSwitch id="privacy2" v-model="checked2"></ToggleSwitch>
            <span class="ml-2">Share my data with contacts</span>
          </div>
        </div>
        <div class="border-surface border-t opacity-50 mb-4 col-span-12"></div>
        <div class="col-span-12">
          <Button label="Save Changes" class="w-auto mt-4"></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>