<script setup>
import {ref} from "vue";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";

const visible = defineModel('visible');
const props = defineProps({visible: Boolean})
const refuel = ref({
  unitNumber: '',
  setpoint: '',
  fuelAdded: null,
  startFuelLevel: '',
  endFuelLevel: '',
  notes: ''
})
const fuelOptions = ref(['E', '1/8', '1/4', '3/8', '5/16', '7/16', '1/2', '9/16', '5/8', '3/4', '13/16', '7/8', 'F'])

</script>

<template>
  <!--v-model:visible="visible"-->
  <Dialog v-model:visible="visible" appendTo="body" modal :breakpoints="{'960px': '75vw', '640px': '100vw'}"
          :style="{width: '30vw'}" header="New Refuel" :draggable="false" :resizable="false">
    <!--    <template #header>-->
    <!--      <div class="flex flex-col gap-2">-->
    <!--        <h1 class="m-0 text-surface-900 dark:text-surface-0 font-semibold text-xl leading-normal">New Refuel</h1>-->
    <!--      </div>-->
    <!--    </template>-->
    <form class="flex flex-col gap-4 mt-4">
      <div class="flex gap-4">
        <div class="w-full">
          <label for="unit-number" class="block mb-1 text-color text-base">Unit Number</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-hashtag mb-2"></i>
            </InputGroupAddon>
            <InputText name="unit-number" v-model="refuel.unitNumber" type="text" class="w-full" id="unit-number"/>
          </InputGroup>
        </div>
      </div>
      <div class="flex gap-4">
        <div class="w-full">
          <label for="start-fuel" class="block mb-1 mt-2 text-color text-base">Start Fuel Level</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-gauge text-lg"></i>
            </InputGroupAddon>
            <Dropdown name="start-fuel" type="text" v-model="refuel.startFuelLevel" class="w-full"
                      id="start-fuel"
                      :options="fuelOptions"/>
          </InputGroup>
        </div>

        <div class="w-full">
          <label for="end-fuel" class="block mb-1 mt-2 text-color text-base">End Fuel</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="pi pi-gauge text-lg"></i>
            </InputGroupAddon>
            <Dropdown name="end-fuel" type="text" v-model="refuel.endFuelLevel" class="w-full" id="end-fuel"
                      :options="fuelOptions.toReversed()"/>
          </InputGroup>
        </div>
      </div>
      <div class="flex gap-4">
        <div class="w-full">
          <label for="setpoint" class="block mb-1 mt-2 text-color text-base">Setpoint</label>
          <InputGroup>
            <InputGroupAddon>
              <i class="fa-solid fa-temperature-three-quarters"></i>
            </InputGroupAddon>
            <InputText name="setpoint" type="text" v-model="refuel.setpoint" class="w-full" id="setpoint"/>
          </InputGroup>
        </div>
        <div class="w-full">
          <label for="fuel-added" class="block mb-1 mt-2 text-color text-base">Fuel Added</label>
          <InputGroup>
            <InputGroupAddon>
<i class="fa-solid fa-gas-pump mr-2 mb-2"></i>
            </InputGroupAddon>
                    <InputText name="fuel-added" type="text" v-model="refuel.fuelAdded" class="w-full" id="expiration"/>
          </InputGroup>
        </div>
      </div>
      <div>
        <label for="notes" class="block mb-1 mt-2 text-color text-base">Notes</label>
        <InputGroup>
          <InputGroupAddon>
                <i class="pi pi-file mb-2"></i>
          </InputGroupAddon>
                <Textarea name="notes" v-model="refuel.notes" class="w-full" id="notes"/>
        </InputGroup>
      </div>
    </form>
    <template #footer>
      <div class="flex gap-4 justify-end border-t border-surface pt-8">
        <Button label="Cancel" @click="visible = false" class="p-button-text"></Button>
        <Button label="Submit" @click="console.log(refuel)" class="p-button-rounded"></Button>
      </div>
    </template>
  </Dialog>

</template>

<style scoped>

</style>