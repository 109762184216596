<script setup>
import {onMounted, ref} from "vue";
import backendCall from "../../services/AxiosService.js";
import { useConfirm } from "primevue/useconfirm";
import {useToast} from "primevue/usetoast";
import ConfirmPopup from "primevue/confirmpopup";

const confirm = useConfirm();
const toast = useToast();
const trainEventSubscriptions = ref(null);

onMounted(async() => {
  const trainEventSubscriptionRequest = await backendCall.get('users/user-train-subscriptions');
  trainEventSubscriptions.value = trainEventSubscriptionRequest.data.data;
})

const confirmDeleteSubscription = (subscriptionId ,event) => {
  confirm.require({
    target: event.currentTarget,
    message: 'Are you sure you want to delete this call request?',
    icon: 'pi pi-info-circle',
    rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
    acceptClass: 'p-button-danger p-button-sm',
    rejectLabel: 'Abort',
    acceptLabel: 'Delete',
    accept: async () => {
      try {
        const deleteSubscriptionCall = await backendCall.delete(`usersww/`)
        if (!deleteSubscriptionCall || deleteSubscriptionCall.status >= 400) {
          throw new Error('API Call Error')
        }
        toast.add({severity: 'success', summary: 'Confirmed', detail: `Subscription for  ${subscriptionId} has been deleted`, life: 5000});
      } catch (e) {
        toast.add({ severity: 'error', summary: 'Failed', detail: `Failed to delete subscription ${subscriptionId}`, life: 5000 });
      }
    },
    reject: () => {
    }
  });
};
</script>

<template>

  <div class="bg-surface-0 dark:bg-surface-900 p-6 shadow rounded-border">
    <div class="mb-4 flex items-center justify-between">
      <span class="text-xl font-medium text-surface-900 dark:text-surface-0">My Subscriptions</span>
<!--      <Button label="New" icon="pi pi-plus"></Button>-->
    </div>
    <div class="border-2 border-dashed border-surface">
      <DataTable :value="trainEventSubscriptions" sortField="id" :sortOrder="-1" tableStyle="min-width: 50rem">
        <Column field="unit_number" header="Unit" :sortable="true"></Column>
        <Column field="call_made" header="Call Made" :sortable="true">
          <template #body="slotProps">
            <Tag v-if="slotProps.data.call_made" value="True" severity="success" rounded></Tag>
            <Tag v-else severity="danger" value="False" rounded></Tag>
          </template>
        </Column>
        <Column field="event_station" header="Event Station" :sortable="true"></Column>
        <Column field="created_at" header="Created At" :sortable="true"></Column>
        <Column field="call_time" header="Call Time" :sortable="true"></Column>
        <Column header="Actions">
          <template #body="slotProps">
            <Button v-if="!slotProps.data.call_made" @click="confirmDeleteSubscription(slotProps.data.id, $event)" icon="pi pi-times" severity="danger" rounded outlined v-tooltip.top="'Cancel call'"></Button>
          <ConfirmPopup/>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

</template>

<style scoped>

</style>