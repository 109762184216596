export function tempWithinThreshold(
    temp1: number,
    unit1: 'C' | 'F',
    temp2: number,
    unit2: 'C' | 'F',
    threshold: number,
    thresholdUnit: 'C' | 'F'
): boolean {
    // Convert all temperatures to Celsius for comparison
    const temp1C = unit1 === 'C' ? temp1 : (temp1 - 32) * 5/9;
    const temp2C = unit2 === 'C' ? temp2 : (temp2 - 32) * 5/9;
    const thresholdC = thresholdUnit === 'C' ? threshold : (threshold * 5/9);

    // Calculate the absolute difference
    const difference = Math.abs(temp1C - temp2C);

    // Compare the difference with the threshold
    return difference <= thresholdC;
}