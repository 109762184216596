<template>
  <li class="flex flex-col md:flex-row md:items-center md:justify-between mb-6">
    <div class="flex"><i class="pi pi-bolt"></i>
      <div><span class="block text-surface-900 dark:text-surface-0 font-medium mb-1">{{ props.clipNumber }}</span>
        <div class="text-surface-600 dark:text-surface-200">Removed {{ removalTimeString }}</div>
      </div>
    </div>
    <div class="mt-2 md:mt-0 flex flex-nowrap">

      <Button v-if="listType==='pretrip-required'" @click="pretripClipDialogVisible = true" outlined size="small">Pretrip</Button>
      <Button v-else-if="listType==='ready-to-install'" @click="installClipDialogVisible = true" outlined size="small">Install</Button>
      <Button v-else @click="pretripClipDialogVisible = true" outlined size="small">Resolve</Button>
      <InstallClipDialog v-model="installClipDialogVisible" :clip-number="props.clipNumber" :event-id="props.eventId" @update-clips="$emit('update-clips')"></InstallClipDialog>
      <PretripClipDialog v-model="pretripClipDialogVisible" :clip-number="clipNumber" :list-type="listType" :event-id="props.eventId" @update-clips="$emit('update-clips')"/>
<!--      <button v-tooltip="'Install Clip'"-->
<!--              class="p-button p-component p-button-icon-only p-button-text p-button-plain p-button-rounded mr-1"-->
<!--              type="button" data-pc-name="button" data-pc-section="root" data-pd-ripple="true"><span-->
<!--          class="p-button-icon pi pi-wrench" data-pc-section="icon"></span><span class="p-button-label"-->
<!--                                                                                 data-pc-section="label">&nbsp;</span>-->
<!--        &lt;!&ndash;&ndash;&gt;<span role="presentation" aria-hidden="true" data-p-ink="true" data-p-ink-active="false"-->
<!--                     class="p-ink" data-pc-name="ripple" data-pc-section="root"></span></button>-->
    </div>
  </li>
</template>
<script setup>
import { ref } from 'vue';
import InstallClipDialog from "./InstallClipDialog.vue";
import PretripClipDialog from "./PretripClipDialog.vue";

const props = defineProps({clipNumber: String, removalTime: String, listType: String, eventId: Number});
const emit = defineEmits(['update-clips']);
const installClipDialogVisible = ref(false);
const pretripClipDialogVisible = ref(false);

const installOverlay = ref(null);

const removalTimeString = new Date(props.removalTime).toLocaleDateString('en-US', {
  hour: '2-digit',
  minute: '2-digit',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hourCycle: 'h23'
});




</script>