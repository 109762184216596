<template>
  <Card>
    <template #header>
      <h3 class="text-center">{{headerTitle}}</h3>
      <div class="flex justify-end">
        <a :href="`https://rms.janssenequipment.com/trains/${$route.params.trainid}/generateelectricpdf`">
        <Button class="mr-6" icon="pi pi-print" severity="success" aria-label="Print" outlined/>
        </a>
      </div>
    </template>
    <template #content>
      <DataTable :value="units" showGridlines stripedRows size="small">
        <Column field="sequence" header="SEQ" :sortable="true"  style="width: 3rem;" class="text-center text-sm"></Column>
        <Column header="Unit" :sortable="true" style="width: 10rem;">
          <template #body="slotProps">
<!--            <div :class="{'dark:bg-yellow-700': slotProps.data.is_priority, 'dark:text-white-900': slotProps.data.is_priority, 'bg-yellow-300': slotProps.data.is_priority}" class="text-center text-sm">-->
              <Button :severity="slotProps.data.is_priority ? 'warn' : null" @click="openUnitWaybillAndLocationDialog(slotProps.data)" rounded :text="!slotProps?.data.is_priority" size="small">
              <div class="text-sm"> {{ slotProps.data.unit_number }} </div>
              </Button>
<!--            </div>-->
          </template>
        </Column>
        <Column field="complete_setpoint_c" header="Setpoint" :sortable="true" style="width: 3rem;" class="text-right text-sm"></Column>
        <Column field="clip_on" header="Clip" :sortable="true" class="text-sm"></Column>
        <Column field="railcar" header="Car" :sortable="true" style="width: 5rem" class="text-center text-sm"></Column>
        <Column style="width: 2rem;">
          <template #body="slotProps">
            <ConfirmPopup></ConfirmPopup>
            <div class="flex justify-between">
              <Button  @click="$emit('open-subscribe-dialog', slotProps.data)" class="mx-1 p-0 my-0"  severity="warning" text rounded aria-label="Notify">
              <i class="pi pi-bell" aria-label="Notify"/>
              </Button>
<!--              <Button @click="confirm1($event)" size="small" class="p-0 my-0" severity="danger" text rounded aria-label="Cancel">-->
<!--              <i class="pi pi-times" aria-label="Delete"/>-->
<!--              </Button>-->
            </div>
          </template>
        </Column>



      </DataTable>
    </template>
  </Card>

  <UnitWaybillAndLocationDialog :unit-info="selectedUnitInfo"  v-model:unit-waybill-and-location-dialog-visible="unitWaybillAndLocationDialogVisible"/>
</template>

<script setup>
import { ref } from "vue";
import ConfirmPopup from "primevue/confirmpopup";
import UnitWaybillAndLocationDialog from "../../waybill/UnitWaybillAndLocationDialog.vue";
// const units = ref();
const props = defineProps({
  headerTitle: String,
  units: Object,
  detailLevel: String
})

const unitWaybillAndLocationDialogVisible = ref(false);
const selectedUnitInfo = ref();

const openUnitWaybillAndLocationDialog = (data) => {
  unitWaybillAndLocationDialogVisible.value = true;
  selectedUnitInfo.value = data;
}

const emit = defineEmits(['open-subscribe-dialog']);



// const rowStyle = (units) => {
//   console.log(units);
//   if (units.is_priority) {
//     // return { outline: '20px solid yellow;', color: 'black' };
//     return ['text-yellow-500']
//   }
// }


</script>
