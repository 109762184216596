<script setup lang="ts">
import {onMounted, ref} from "vue";
import backendCall from "../../services/AxiosService";

const fleetSummaryData = ref();

const fetchFleetData = async () => {
  const fetchFleetDataCall = await backendCall.get("/fleet");
  fleetSummaryData.value = fetchFleetDataCall.data.data;
}

onMounted(async () => {
  await fetchFleetData()
})
</script>

<template>
  <div class="bg-surface-0 dark:bg-surface-900 p-6 shadow">
    <div class="mb-4 flex items-center justify-between">
      <div></div>
      <span class="text-xl font-medium text-surface-900 dark:text-surface-0">Fleet Summary</span>
      <span></span>
    </div>
    <div v-if="fleetSummaryData" class="border-2 border-solid p-1 border-opacity-100 rounded-xl border-surface">
      <DataTable :value="fleetSummaryData" sort-field="gps.address.name" :sort-order="-1" data-key="id">
        <Column field="name" :sortable="true" header="Vehicle"></Column>
        <Column field="gps.address.name" :sortable="true" header="Location"></Column>
        <Column field="fuelPercent.value" :sortable="true" header="Fuel Level">
          <template #body="slotProps">
            <ProgressBar v-if="slotProps.data.fuelPercent?.value" :value="slotProps.data.fuelPercent.value"></ProgressBar>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<style scoped>

</style>