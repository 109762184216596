import { createApp } from 'vue'
import { createPinia } from 'pinia';
import * as Sentry from "@sentry/vue";
import PrimeVue from 'primevue/config';
import ToastService from "primevue/toastservice";
import 'primeicons/primeicons.css';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import ConfirmationService from 'primevue/confirmationservice';
import vBadge from 'primevue/badge';
import Tooltip from 'primevue/tooltip';
import Aura from '@primevue/themes/aura';
import './style.css'
import App from './App.vue'
import router from './router/index';
import { useAuthStore} from "./stores/authStore";
import backendCall from "./services/AxiosService.js";


const app = createApp(App);



const pinia = createPinia();


app.use(PrimeVue, {
    // Default theme configuration
    theme: {
        preset: Aura,
        options: {
            prefix: '',
            darkModeSelector: '.dark',
            cssLayer: {
                name: 'primevue',
                order: 'tailwind-base, primevue, tailwind-utilities'
            }
        }
    },
    // ptOptions: {
    //     mergeSections: true,
    //     mergeProps: false
    // },
    ripple: true
});


Sentry.init({
    app,
    dsn: "https://bd68b43fbdbb1eef14099b622b52831a@o4507087639347200.ingest.us.sentry.io/4507223274684416",
    logErrors: true,
    environment: import.meta.env.MODE,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false
        }),
        Sentry.feedbackIntegration({
            // Additional SDK configuration goes in here, for example:
            colorScheme: 'light',
            showEmail: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router);
app.use(pinia);
app.use(ToastService);
app.use(ConfirmationService);

const authStore = useAuthStore();

// const tokenFromLocalStorage = localStorage.getItem('authToken');
// if (tokenFromLocalStorage) {
//     authStore.setToken(tokenFromLocalStorage);
// }

app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);
app.directive('badge', vBadge);
app.directive('tooltip', Tooltip);


app.mount('#app');

// Auto Logout if token is expired
backendCall.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
           await authStore.logout();
          await router.push('/login');

        }
    }
)