<script setup lang="ts">
import { onMounted, ref} from "vue";
import backendCall from "../../services/AxiosService.js";
import UnitCheckInfoDialog from "../../components/yard-check/UnitCheckInfoDialog.vue";

const BACKEND_BASE_URL = import.meta.env.VITE_BACKEND_BASE_URL;

const props = defineProps({yardCheckId: String});

const yardCheckUnits = ref({});

const electricAlarmsPopup = ref();
const selectedElectricUnitAlarmData = ref(null);

const domesticFuelDataPopup = ref();
const electricFuelDataPopup = ref();

const selectedElectricFuelData = ref(null);
const selectedDomesticFuelData = ref(null);

const showElectricFuelDetails = (event, data) => {
  selectedElectricFuelData.value = data;
  electricFuelDataPopup.value.toggle(event);
};

const showDomesticFuelDetails = (event, data) => {
  selectedDomesticFuelData.value = data;
  domesticFuelDataPopup.value.toggle(event);
};

const toggleElectricsAlarmsPopup = (event, data) => {
  selectedElectricUnitAlarmData.value = data;
  electricAlarmsPopup.value.toggle(event);
}

const domesticAlarmsPopup = ref();
const selectedDomesticUnitAlarmData = ref(null);

const toggleDomesticsAlarmsPopup = (event, data) => {
  selectedDomesticUnitAlarmData.value = data;
  domesticAlarmsPopup.value.toggle(event);
}

const fetchYardCheck = async () => {
  const fetchYardChecksCall = await backendCall.get(`yard-check/${props.yardCheckId}?sortmode=sorted`);
  yardCheckUnits.value = fetchYardChecksCall.data.data;
}

onMounted(async () => {
  await fetchYardCheck();
})

const setpointDiscrepancyStyling = (units) => {
  if (units.setpoint_discrepancy_flag) {
    // return { outline: '20px solid yellow;', color: 'black' };
    // return { background: '#fbfbb4', color: 'black' };
    // return { background: '#f80505', color: 'black' };
    return {color: '#dd7c7c' };

  }
};

const outOfRangeStyling = (units) => {
  if (units.out_of_range_flag) {
    // return { outline: '20px solid yellow;', color: 'black' };
    // return { background: '#fbfbb4', color: 'black' };
    // return { background: '#f80505', color: 'black' };
    return {color: '#dd7c7c' };

  }
};

const fuelWarningStyle = (unit) => {
  if ((unit.start_fuel_level === '1/8' || unit.start_fuel_level === '1/4' || unit.start_fuel_level === '3/8' || unit.start_fuel_level === '7/16' || unit.start_fuel_level === '1/2' || unit.start_fuel_level === '5/8' || unit.start_fuel_level === '9/16' || unit.start_fuel_level === '3/4') && !unit.fuel_added && (unit.event_code === 'IG' || unit.event_code === 'AS' || !unit.event_code)) {
    // return { outline: '20px solid yellow;', color: 'black' };
    return {color: 'red'};
  }
};

const electricCheckInfoDialogVisible = ref(false);
const domesticCheckInfoDialogVisible = ref(false);
const selectedElectricCheck = ref({});
const selectedDomesticCheck = ref({});


const openElectricCheckInfoDialog = (selectedElectricCheckData) => {
  selectedElectricCheck.value = selectedElectricCheckData;
  electricCheckInfoDialogVisible.value = true;
}

const openDomesticCheckInfoDialog = (selectedDomesticCheckData) => {
  selectedDomesticCheck.value = selectedDomesticCheckData;
  domesticCheckInfoDialogVisible.value = true;
}

</script>

<template>
  <div v-if="yardCheckUnits" class=" flex flex-col w-full max-w-full bg-surface-0 dark:bg-surface-900 p-2 shadow rounded-border text-sm overflow-x-hidden">

    <div id="page-title" class="mb-4 flex items-center w-full justify-between">
      <div></div>
      <span v-if="yardCheckUnits?.yardCheck?.date" class="text-xl font-medium text-surface-900 dark:text-surface-0">{{new Date(yardCheckUnits?.yardCheck?.date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        timeZone: 'UTC'
      }) }} {{yardCheckUnits?.yardCheck?.yard_location === 'CN-WIT' ? 'WIT' : ''}} {{yardCheckUnits?.yardCheck.morning_afternoon}} Yard Check</span>
      <a class="mr-4" :href="`${BACKEND_BASE_URL}yardcheck/${props.yardCheckId}/generatepdf`">
      <Button label="Print" icon="pi pi-print" severity="success"></Button>
      </a>
    </div>

    <div class="flex flex-col xl:flex-row justify-center gap-3 overflow-y-auto">
      <Card>
        <template #header>
          <h3 class="text-center mt-2 font-bold">Electrics ({{yardCheckUnits?.electrics?.length}})</h3>
          <div class="flex justify-end">
          </div>
        </template>
        <template #content>
          <div class="border-2 border-solid border-surface rounded-xl p-1">
            <DataTable :value="yardCheckUnits.electrics" size="small" data-key="yard_check_id">
              <Column field="unit_number" header="Unit"></Column>
              <Column field="event_code" header="EV"></Column>
              <Column header="Billed SP" field="billed_setpoint">
                <template #body="slotProps">
                  <div :style="setpointDiscrepancyStyling(slotProps.data)">
                    {{ slotProps.data.billed_setpoint }}{{slotProps.data.billed_setpoint ? slotProps.data.billed_setpoint_unit : null }}
                  </div>
                </template>
              </Column>
              <Column header="Actual SP">
                <template #body="slotProps">
                  <div :style="setpointDiscrepancyStyling(slotProps.data)">
                  {{ slotProps.data.actual_setpoint }}{{slotProps.data.actual_setpoint ? slotProps.data.actual_setpoint_unit : null }}
                  </div>
                </template>
              </Column>
              <Column field="return_temp" header="Return">
                <template #body="slotProps">
                  <div :style="outOfRangeStyling(slotProps.data)">
                  {{ slotProps.data.return_temp }}{{slotProps.data.return_temp ? slotProps.data.return_temp_unit: null }}
                  </div>
                </template>
              </Column>
              <Column field="supply_temp" header="Supply">
                <template #body="slotProps">
                  {{ slotProps.data.supply_temp }}{{slotProps.data.supply_temp ? slotProps.data.supply_temp_unit : null}}
                </template>
              </Column>
              <Column header="Fuel Level">
                <template #body="slotProps">
                  <span :style="fuelWarningStyle(slotProps.data)">{{ slotProps.data.start_fuel_level }}</span>
                  <i v-if="slotProps.data.fuel_added" @click="showElectricFuelDetails($event, slotProps.data)"
                     class="fa-solid fa-gas-pump ml-2" style="color: green"></i>

                  <Popover ref="domesticFuelDataPopup">
                    <template #default="{ data }">
                      <div class="flex flex-col gap-4 w-[25rem]">
                        <div v-if="selectedElectricFuelData">
                          <div class="font-bold">Unit Refuelled</div>
                          <div>Start Fuel Level: {{ selectedElectricFuelData.start_fuel_level }}</div>
                          <div> Fuel Added: {{ selectedElectricFuelData.fuel_added }}</div>
                          <div> End Fuel Level: {{ selectedElectricFuelData.end_fl }}</div>
                        </div>
                      </div>
                    </template>
                  </Popover>
                </template>
              </Column>
              <Column header="Alarms">
                <template #body="slotProps">
                  <Button v-if="slotProps.data.alarms.length > 0" @click="toggleElectricsAlarmsPopup($event, slotProps.data)" rounded icon="pi pi-exclamation-circle"
                          severity="warn"/>
                  <Popover v-if="slotProps.data.alarms.length > 0" ref="electricAlarmsPopup">
                    <template #default="{ data }">
                      <div class="flex flex-col gap-4 w-[25rem]">
                        <div class="font-bold text-center">{{selectedElectricUnitAlarmData.unit_number}} Alarm List</div>
                        <div v-for="alarm of selectedElectricUnitAlarmData.alarms">{{alarm.alarm_code}} - {{alarm.alarm_name}}</div>
                        <!--                          <div class="font-bold">{{ slotProps.data.unit_number }} Alarm List</div>-->
                        <!--                          <div> Event Code: {{selectedDomesticAdditionalInfo.event_code}}</div>-->

                      </div>
                    </template>
                  </Popover>
                </template>
              </Column>
              <Column header="Actions">
                <template #body="slotProps">
                  <Button label="View" outlined @click="openElectricCheckInfoDialog(slotProps.data)" />
                </template>
              </Column>
            </DataTable>
          </div>

          <UnitCheckInfoDialog v-model:unitCheckInfoDialogVisible="electricCheckInfoDialogVisible" :unit-info="selectedElectricCheck"></UnitCheckInfoDialog>



        </template>
      </Card>
      <Card>
        <template #header>
          <h3 class="text-center mt-2 font-bold">Domestics ({{yardCheckUnits?.domestics?.length}})</h3>
          <div class="flex justify-end">
          </div>
        </template>
        <template #content>
          <div class="border-2 border-solid border-surface rounded-xl p-1">
            <DataTable :value="yardCheckUnits.domestics" size="small" data-key="yard_check_id">
              <Column field="unit_number" header="Unit"></Column>
              <Column field="event_code" header="EV"></Column>
              <Column header="Billed SP" field="billed_setpoint">
                <template #body="slotProps">
                  {{slotProps.data.billed_setpoint }}{{slotProps.data.billed_setpoint ? slotProps.data.billed_setpoint_unit : null}}
                </template>
              </Column>
              <Column header="Actual SP">
                <template #body="slotProps">
                  <div :style="setpointDiscrepancyStyling(slotProps.data)">
                  {{ slotProps.data.actual_setpoint }}{{ slotProps.data.actual_setpoint ? slotProps.data.actual_setpoint_unit : null }}
                  </div>
                </template>
              </Column>
              <Column field="return_temp" header="Return">
                <template #body="slotProps">
                  <div :style="outOfRangeStyling(slotProps.data)">
                  {{ slotProps.data.return_temp }}{{ slotProps.data.return_temp ? slotProps.data.return_temp_unit : null }}
                  </div>
                </template>
              </Column>
              <Column field="supply_temp" header="Supply">
                <template #body="slotProps">
                  {{ slotProps.data.supply_temp }}{{ slotProps.data.supply_temp ? slotProps.data.supply_temp_unit : null }}
                </template>
              </Column>
              <Column header="Fuel Level">
                <template #body="slotProps">
                  <span :style="fuelWarningStyle(slotProps.data)">{{ slotProps.data.start_fuel_level }}</span>
                  <i v-if="slotProps.data.fuel_added" @click="showDomesticFuelDetails($event, slotProps.data)"
                     class="fa-solid fa-gas-pump ml-2" style="color: green"></i>

                  <Popover ref="domesticFuelDataPopup">
                    <template #default="{ data }">
                      <div class="flex flex-col gap-4 w-[25rem]">
                        <div v-if="selectedDomesticFuelData">
                          <div class="font-bold">Unit Refuelled</div>
                          <div>Start Fuel Level: {{ selectedDomesticFuelData.start_fuel_level }}</div>
                          <div> Fuel Added: {{ selectedDomesticFuelData.fuel_added }}</div>
                          <div> End Fuel Level: {{ selectedDomesticFuelData.end_fl }}</div>
                        </div>
                      </div>
                    </template>
                  </Popover>
                </template>
              </Column>
              <Column header="Alarms">
                <template #body="slotProps">
                  <Button v-if="slotProps.data.alarms.length > 0" @click="toggleDomesticsAlarmsPopup($event, slotProps.data)" rounded icon="pi pi-exclamation-circle"
                          severity="warn"/>
                  <Popover v-if="slotProps.data.alarms.length > 0" ref="domesticAlarmsPopup">
                    <template #default="{ data }">
                      <div class="flex flex-col gap-4 w-[25rem]">
                        <div class="font-bold text-center">{{selectedDomesticUnitAlarmData.unit_number}} Alarm List</div>
                        <div v-for="alarm of selectedDomesticUnitAlarmData.alarms">{{alarm.alarm_code}} - {{alarm.alarm_name}}</div>
                        <!--                          <div class="font-bold">{{ slotProps.data.unit_number }} Alarm List</div>-->
                        <!--                          <div> Event Code: {{selectedDomesticAdditionalInfo.event_code}}</div>-->

                      </div>
                    </template>
                  </Popover>
                </template>
              </Column>
              <Column header="Actions">
                <template #body="slotProps">
                  <Button label="View" outlined @click="openDomesticCheckInfoDialog(slotProps.data)" />
                </template>
              </Column>
            </DataTable>
          </div>

        </template>
      </Card>
    </div>
  </div>
  <UnitCheckInfoDialog v-model:unitCheckInfoDialogVisible="domesticCheckInfoDialogVisible" :unit-info="selectedDomesticCheck"></UnitCheckInfoDialog>

</template>

<style scoped>

</style>