<script setup>
import { ref } from 'vue';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import {onMounted} from "vue";
const mapRef = ref(null);

const props = defineProps({coordinates: String})
let geojson;


onMounted(() => {
   geojson = {
    type: 'FeatureCollection',
    features: [{
      type: 'Feature',
      geometry: JSON.parse(props.coordinates),
      properties: {},
    }],
  };

  mapboxgl.accessToken = 'pk.eyJ1IjoiamFycm9kamFuc3NlbiIsImEiOiJjbHZsYTc2c2IyM3IxMmxuNThzZGZ4Nm85In0.fqOgHqvCLZQwiMXnoaTDVg';

  const map = new mapboxgl.Map({
    container: mapRef.value, // container ID
    style: 'mapbox://styles/mapbox/satellite-streets-v12', // style URL
    center: geojson.features[0].geometry.coordinates, // starting position [lng, lat]
    zoom: 14, // starting zoom
  });
  map.resize();
  const marker1 = new mapboxgl.Marker()
      .setLngLat(geojson.features[0].geometry.coordinates)
      .addTo(map);
})


// console.log(geojson.features[0].geometry.coordinates)
</script>

<template>
  <div style="height: 100%; width: 100%" id="map" ref="mapRef"></div>
</template>

<style scoped>

</style>