<template>

  <div class="bg-surface-0 dark:bg-surface-900 p-1 pt-0 shadow rounded-border">
    <div v-once class="mb-4 flex items-center justify-between">
      <span></span>
      <span class="text-xl font-medium text-surface-900 dark:text-surface-0">Train Lineup</span>
      <span>
      <Button @click="newTrainDialogVisible = true" label="New" icon="pi pi-plus"></Button>
      <Button @click="$refs.ellipsismenu.toggle($event)" class="m-2 border-0" rounded text>
        <i class="pi pi-ellipsis-v"></i>
      </Button>
      </span>
      <Menu ref="ellipsismenu" class="mt-2" appendTo="body" popup :model="ellipsisButtonItems"></Menu>
    </div>
    <div class="border-2 border-surface-200 dark:border-surface-600 border-surface mb-2 rounded-xl">
      <DataView class="m-2 p-2" :value="trains" :data-key="trains.train_row_id">
        <template #empty>
          No Trains On Lineup.
        </template>
        <template #list="slotProps">
          <div class="grid grid-cols-12 grid-nogutter">
            <div v-for="(item, index) in slotProps.items" :key="item.train_row_id" class="col-span-12">
            <TrainLineupIndividualTrain :train="item"/>
              <Divider v-if=" index + 1 < slotProps.items.length" class="m-0 p-0"/>
            </div>
          </div>
        </template>
      </DataView>
    </div>
  </div>
  <NewTrainDialog :visible="newTrainDialogVisible" @visibleChange="newTrainDialogVisible = false"
                  @fetch-trains="fetchTrains"/>
</template>


<script setup>
import {useAuthStore} from '../stores/authStore';
import {onMounted, onUnmounted, ref} from 'vue';
import backendCall from "../services/AxiosService.js";
import NewTrainDialog from "./trains/NewTrainDialog.vue";
import router from "../router/index.js";
import TrainLineupIndividualTrain from "./TrainLineupIndividualTrain.vue";

const authStore = useAuthStore();
const trains = ref([]);
let fetchTrainsInterval;
const selectedTrains = ref(null);
const locationsLoading = ref(false);
const newTrainDialogVisible = ref(false);

const ellipsisButtonItems = ref([{
  label: 'Pasteable Lineup',
  icon: 'pi pi-clipboard',
  command: () => {
    router.push( '/trains/lineup/pasteable');
  }
}
]);

// let index = t.train_id.length - 2;
// let dashedTrainId = t.train_id.slice(0, index) + "-" + t.train_id.slice(index);

const fetchTrains = () => {
  locationsLoading.value = true;
  backendCall.get('trains/lineup')
      .then(async response => {
        locationsLoading.value = false;
        const data = response.data;
        if (Array.isArray(data.content)) {
          const newTrains = data.content.map(train => ({
            ...train,
            origin_depart_date_string: new Date(train.train_origin_departure_date).toDateString(),
            eta_string: train.eta ? new Date(train.eta).toLocaleString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hourCycle: 'h23'
            }) : null,
            location: '',
          }));

          //
          // await newTrains.forEach((train, index) => {
          //   fetchLocations(train.train_row_id, index);
          //   console.log(train);
          // });

          // Check if new data is different from current data
          if (JSON.stringify(newTrains) !== JSON.stringify(trains)) {
            trains.value = newTrains;
          }

        } else {
          throw new Error('Data content is not an array');
        }
      })
      .catch(error => {
        console.error('There was a problem with the axios operation:', error);
      });
}

// const fetchLocations = (trainId, index) => {
//   backendCall.get(`trains/locationbyid?trainid=${trainId}`, {
//     headers: {
//       "authorization": `Bearer ${authStore.token}`,
//     }
//   })
//       .then(response => {
//             const data = response.data.data;
//             const newLocation = data.station ? `${data.description} ${data.station} ${data.provstate} @ ${data.time}` : null;
//             const currentLocation = trains.value[index].location;
//
//             // Only update if the data has changed
//             if (newLocation !== currentLocation) {
//               trains.value[index].location = newLocation;
//               trains.value[index].algorithm_eta_interval = data.etaIntervalString;
//               console.log('DATA.ETATIME: ', data.etaTime)
//               trains.value[index].algorithm_eta = data.etaTime ? new Date(data.etaTime).toLocaleString('en-US', {
//                 hour: '2-digit',
//                 minute: '2-digit',
//                 year: 'numeric',
//                 month: 'long',
//                 day: 'numeric',
//                 hourCycle: 'h23'
//               }) : null
//             }
//           }
//       )
//       .catch(error => {
//         console.error('Error fetching location:', error);
//         trains.value[index].location = 'Unavailable';
//       })
//       .then(() => {
//         // Log trains value after fetching or in case of an error
//         console.log('Trains Value: ', trains.value);
//       });
// }

onMounted(() => {
  fetchTrains();
  fetchTrainsInterval = setInterval(fetchTrains, 60000);
});

onUnmounted(() => {
  clearInterval(fetchTrainsInterval)
})
</script>
