<template>
  <div class="container p-8">
    <h2 class="mb-6 text-center">Add Electrics to Train {{ dashedTrainId }}</h2>
    <form @submit.prevent="submitForm">
      <div class="p-fluid grid grid-cols-12 gap-4 mb-4">
        <div class="field col-span-12 md:col-span-3">
          <FloatLabel>
          <label for="seq">Sequence</label>
          <InputText v-model="seq" id="seq" />
          </FloatLabel>
          <label class="mt-4" for="car_number">Car Number:</label>
          <InputText v-model="carNumber" id="car_number" />
          <Button label="Add Row" @click="addRow" class="mt-2" />
        </div>
        <div class="col-span-12 md:col-span-6"></div>
        <div class="field col-span-12 md:col-span-3">
          <label for="genset_sequence">Sequence</label>
          <InputText v-model="gensetSequence" id="genset_sequence" />
          <label for="genset_id">Genset ID:</label>
          <InputText v-model="gensetId" id="genset_id" />
          <Button label="Add Genset" class="p-button-secondary mb-4 mr-2 mt-2" @click="addGenset" />
          <DataTable :value="gensets" class="mt-8">
            <Column field="seq" header="SEQ" />
            <Column field="genset" header="Genset ID" />
            <Column header="Actions">
              <template #body="slotProps">
                <Button label="Remove" class="p-button-danger" @click="removeGenset(slotProps.rowIndex)" />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>

      <DataTable :value="rows" edit-mode="cell" showGridlines @cell-edit-complete="onCellEditComplete" :row-style="rowStyle">
        <Column field="seq" header="Seq" style="width: 4rem">
          <template #body="slotProps">
            {{ slotProps.data.seq }}
          </template>
          <template #editor="slotProps">
            <InputText v-model="slotProps.data.seq" style="width: 4rem" />
          </template>
        </Column>
        <Column field="unitNumber" header="Unit Number">
          <template #body="slotProps">
            {{ slotProps.data.unitNumber }}
          </template>
          <template #editor="slotProps">
            <InputText v-model="slotProps.data.unitNumber" />
          </template>
        </Column>
        <Column field="setpoint" header="Setpoint" style="width: 4rem">
          <template #body="slotProps">
            {{ slotProps.data.setpointString }}
          </template>
          <template #editor="slotProps">
            <InputText v-model="slotProps.data.setpointString" style="width: 4rem" />
          </template>
        </Column>
        <Column field="carNumber" header="Car Number">
          <template #body="slotProps">
            {{ slotProps.data.carNumber }}
          </template>
          <template #editor="slotProps">
            <InputText v-model="slotProps.data.carNumber" />
          </template>
        </Column>
        <Column field="clipNumber" header="Clip Number">
          <template #body="slotProps">
            {{ slotProps.data.clipNumber }}
          </template>
          <template #editor="slotProps">
            <InputText v-model="slotProps.data.clipNumber" />
          </template>
        </Column>
        <Column header="Priority">
          <template #body="slotProps">
            <Button label="Priority" class="p-button-warning" @click="setPriority(slotProps.data)" />
          </template>
        </Column>
        <Column header="Notes">
          <template #body="slotProps">
            <Textarea v-model="slotProps.data.notes" rows="1" />
          </template>
        </Column>
        <Column header="Actions">
          <template #body="slotProps">
            <Button outlined rounded icon="pi pi-times" severity="danger" @click="removeRow(slotProps.rowIndex)" />
          </template>
        </Column>
      </DataTable>

      <div class="form-group mt-8 flex justify-center">
        <FloatLabel>
        <label>Global Notes:</label>
        <Textarea v-model="globalNotes" id="global_notes" rows="3" cols="125" :auto-resize="true" />
        </FloatLabel>
      </div>
      <div class="border-surface border-t opacity-50 col-span-12"></div>
      <Button type="submit" label="Submit" class="mt-6" />
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Textarea from 'primevue/textarea';
import FloatLabel from "primevue/floatlabel";

const trainId = "someTrainId"; // replace with actual trainId
const seq = ref('');
const carNumber = ref('');
const gensetSequence = ref('');
const gensetId = ref('');
const globalNotes = ref('');
const rows = ref([]);
const gensets = ref([]);

const dashedTrainId = computed(() => {
  const index = trainId.length - 2;
  return trainId.slice(0, index) + "-" + trainId.slice(index);
});

const onCellEditComplete = (event) => {
  let { data, newValue, field } = event;

   data[field] = newValue;
};

const rowStyle = (rows) => {
  console.log(rows);
  if (rows.priority) {
    // return { outline: '20px solid yellow;', color: 'black' };
    return { background: 'rgba(238,112,26,0.25)', color: 'black' };
  }
};

const addRow = () => {
  rows.value.push({
    seq: seq.value,
    carNumber: carNumber.value,
    unitNumber: '',
    setpoint: '',
    clipNumber: '',
    notes: '',
    priority: false
  });
};

const addGenset = () => {
  gensets.value.push({
    seq: gensetSequence.value,
    genset: gensetId.value
  });
  gensetSequence.value = '';
  gensetId.value = '';
};

const removeRow = (index) => {
  rows.value.splice(index, 1);
};

const removeGenset = (index) => {
  gensets.value.splice(index, 1);
};

const setPriority = (row) => {
  row.priority = !row.priority;
};

const submitForm = () => {
  const data = {
    trainData: {
      electricNotes: globalNotes.value,
      gensets: gensets.value
    },
    rows: rows.value
  };
  fetch('/submit_url', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(response => {
    if (!response.ok) throw new Error('Network response was not ok');
    return response.json();
  }).then(data => {
    // success code here
    console.log(data);
    localStorage.removeItem(`train-${trainId}-addElectrics-tempStorage`);
    window.location.replace('./');
  }).catch(error => {
    // error handling here
    console.error('There has been a problem with your fetch operation:', error);
  });
};

const loadLocalStorage = () => {
  const storedData = JSON.parse(localStorage.getItem(`train-${trainId}-addElectrics-tempStorage`)) || {
    trainData: { notes: '', gensets: [] },
    rows: []
  };
  globalNotes.value = storedData.trainData.notes;
  gensets.value = storedData.trainData.gensets;
  rows.value = storedData.rows;
};

onMounted(loadLocalStorage);
</script>

<style>
/* Add any additional styling here */
</style>