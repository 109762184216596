<script setup>

const props = defineProps({
  bannerTitle: String,
  bannerDescription: String,
  infoLink: String,
})

defineEmits(['closeButton'])
</script>

<template>
  <div class="bg-slate-900 text-gray-100 p-4 flex justify-between lg:justify-between items-center flex-wrap">
    <div class="font-bold mr-20">{{props.bannerTitle}}</div>
    <div class="items-center hidden lg:flex">
      <span class="leading-normal">{{props.bannerDescription}}</span>
    </div>
    <a class="flex items-center ml-2 mr-20">
      <a class="text-white" href="#"><span class="underline font-bold">{{props.infoLink}}</span></a>
    </a>
    <a @click="$emit('closeButton')" v-ripple class="flex items-center no-underline justify-center rounded-full text-gray-50 hover:bg-slate-700 cursor-pointer transition-colors duration-150 p-ripple" style="width:2rem; height: 2rem">
      <i class="pi pi-times"></i>
    </a>
  </div>
</template>

<style scoped>

</style>