<template>
  <div class="bg-white dark:bg-neutral-900 rounded-lg shadow-md p-4 md:p-1 hover:shadow-lg transition-shadow duration-300">
    <div class="flex flex-col md:flex-row items-center mx-2 justify-between gap-4">
      <!-- Train ID -->
      <div class="w-full md:w-auto">
        <router-link :to="`/trains/${train.train_row_id}`" class="block">
          <Button :label="train.dashed_train_id" class="w-full md:w-auto" />
        </router-link>
      </div>

      <!-- Train Details -->
      <div class="flex flex-col gap-1 justify-center md:w-auto">
        <div>
        <span class="text-sm font-semibold text-surface-900 dark:text-surface-0">{{ trainLocationString ? trainLocationString : "Location Unavailable" }}</span><span v-if="trainLocationData?.unreliable" v-tooltip="{ value: 'Location may be unreliable!', showDelay: 1000, hideDelay: 300 }" class="mx-2 text-red-600"><i class="pi align-middle pi-exclamation-circle text-lg"></i></span>
        </div>
        <Tag class="text-center w-fit" v-if="train?.eta_string" severity="info">{{ train.eta_string }} from CN</Tag>
          <Tag class=" text-center w-fit" v-else severity="danger">No ETA</Tag>

          <Tag class="w-fit" v-if="trainLocationData?.etaTime" severity="success">{{new Date(trainLocationData.etaTime).toLocaleString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hourCycle: 'h23'
          }) }} Calculated</Tag>
          <Tag class="w-fit" v-else severity="danger">Could Not Calculate ETA</Tag>

          <Tag class="w-fit" v-if="trainLocationData?.etaIntervalString" severity="info">
            {{ trainLocationData.etaIntervalString }} Calculated
          </Tag>

      </div>

      <!-- Unit Badges and Inspect Button -->
      <div class="flex flex-col items-center md:items-end gap-4">
        <UnitTypeBadges
            :electric-count="train.electricCount"
            :diesel-count="train.dieselCount"
            :request-count="train.requestCount"
            :genset-count="train.gensetCount"
        />
<!--        <RouterLink :to="`/trains/${train.train_row_id}/fieldui/`">-->
<!--          <Button severity="success" label="Inspect Train" icon="pi pi-search" />-->
<!--        </RouterLink>-->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, onMounted, onUnmounted} from 'vue';
import { useAuthStore } from '../stores/authStore';
import backendCall from '../services/AxiosService';
import UnitTypeBadges from './trains/UnitTypeBadges.vue';

const props = defineProps<{ train: Object }>();
const authStore = useAuthStore();

const trainLocationData = ref<any>(null);
const trainLocationString = ref<string | null>(null);

const fetchLocations = async () => {
  try {
    const trainLocationCall = await backendCall.get(`trains/locationbyid?trainid=${props.train.train_row_id}`, {
      headers: { authorization: `Bearer ${authStore.token}` },
    });
    if (trainLocationCall && trainLocationCall.data && trainLocationCall.data.data) {
      trainLocationData.value = trainLocationCall.data.data;
      trainLocationString.value = trainLocationCall.data.data.station
          ? `${trainLocationData.value.description} ${trainLocationData.value.station} ${trainLocationData.value.provstate} @ ${trainLocationData.value.time}`
          : null;
    }
  } catch (error) {
    console.error('Error fetching train location:', error);
  }
};

let refreshInterval: number | null = null;

onMounted(() => {
  fetchLocations();
  refreshInterval = setInterval(fetchLocations, 30000);

  onUnmounted(() => {
    if (refreshInterval !== null) {
      clearInterval(refreshInterval);
    }
  });
});
</script>